import { Card, CardContent, CardHeader, Grid, Typography } from '@mui/material'
import MiniStatisticsCard from 'components/Cards/StatisticsCards/MiniStatisticsCard'
import SoftBox from 'components/SoftBox'
import SoftTypography from 'components/SoftTypography'
import { CustomDescrypt } from 'global/func'
import React, { useEffect, useMemo, useState } from 'react'
import { Route, Routes } from 'react-router-dom'
import userStore from 'store/userStore'
import Detail from './Detail'
import { getDataWithResponse } from 'global/getFunc'
import { postDataWithResponse } from 'global/postFunc'
import axios from 'axios'
import { serverUrl } from 'global/variable'
import ServerSideTable from 'myComponent/Table/ServerSideTable'

const SuaraTambahan = () => {
    return (
        <Routes>
            <Route exact path='' element={<Data />} />
            <Route exact path='detail' element={<Detail />} />
            {/* <Route exact path='tambah' element={<Tambah />} /> */}
            {/* <Route exact path='edit' element={<Edit />} /> */}
        </Routes>
    )
}

const Data = () => {
    const { user, addReq } = userStore(state => ({ user: state.user, addReq: state.addReq }));
    const decryptedUser = (user === null || user === undefined || user === '') ? "" : JSON.parse(CustomDescrypt(user));

    const [tableData, setTableData] = useState([{},{}]);
    const [rowCount, setRowCount] = useState(0);
    const [listPj, setListPj] = useState([]);

    const [loading, setLoading] = useState(true)

    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 10, //customize the default page size
    });
    // const [globalFilter, setGlobalFilter] = useState('')
    const [columnFilter, setColumnFilter] = useState([])

    const [listKecs, setListKecs] = useState([])
    const [listKels, setListKels] = useState([])

    useEffect(() => {
        getKec()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getKec = async () => {
        addReq();
        const kec = await axios.get(serverUrl + '/kecamatan');
        setListKecs(kec.data)
    }

    useEffect(() => {
        let object = columnFilter.find(obj => {
            return obj.id === 'id_kec'
        })

        if (object !== undefined) {
            console.log(object);

            getDesa(object.value)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [columnFilter])

    const getDesa = async (params) => {
        addReq()
        const data = await axios.get(serverUrl + '/desa/' + params);
        setListKels(data.data)
        console.log(data);
    }

    useEffect(() => {
        console.log(columnFilter);

        requestData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [columnFilter, pagination])

    useEffect(() => {
        requestPj()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const requestPj = () => {
        setLoading(true)
        addReq()
        getDataWithResponse(
            '/entry/suara-tambahan/pj',
            decryptedUser,
            (res) => {
                setLoading(false)
                setListPj(res)
            },
            (res) => {
                setLoading(false)
                console.log(res);
            }
        );
    }

    const requestData = () => {
        const frm = {
            pagination: pagination,
            columnFilter: columnFilter
        };
        setLoading(true)
        addReq()
        postDataWithResponse(
            '/admin/suara-tambahan',
            frm,
            decryptedUser,
            (response) => {
                setLoading(false)
                setTableData(response.data)
                setRowCount(response.rowCount)
            },
            (error) => {
                setLoading(false)
                console.log(error)
            }
        )
    }

    const [listKecFilter, setListKecFilter] = useState([])
    const [listKelFilter, setListKelFilter] = useState([])
    const [listPjFilter, setListPjFilter] = useState([])

    useEffect(() => {
        setListKecFilter([])
        listKecs.forEach((item, index) => {
            let x = { label: item.nama_kec, value: item.id_kec };
            setListKecFilter(listKecFilter => [...listKecFilter, x])
        })
    }, [listKecs])

    useEffect(() => {
        setListKelFilter([])
        listKels.forEach((item, index) => {
            let x = { label: item.nama_desa, value: item.id_desa };
            setListKelFilter(listKelFilter => [...listKelFilter, x])
        })

    }, [listKels])

    useEffect(() => {
        setListPjFilter([])
        listPj.forEach((item, index) => {
            let x = { label: item.nama, value: item.id };
            setListPjFilter(listPjFilter => [...listPjFilter, x])
        })

    }, [listPj])

    const columns = useMemo(() => [
        {
            accessorKey: 'no_kk',
            header: 'No KK',
            filterVariant: 'text',
        },
        {
            accessorKey: 'nik',
            header: 'NIK'
        },
        {
            accessorKey: 'nama',
            header: 'Nama'
        },
        {
            accessorKey: 'id_kec',
            header: 'Kec',
            Cell: ({ cell }) => (
                <Typography fontSize='small' variant='body2'>{cell.row.original.nama_kec}</Typography>
            ),
            filterSelectOptions: listKecFilter,
            filterVariant: 'select',
        },
        {
            accessorKey: 'id_kel',
            header: 'Kel/Desa',
            Cell: ({ cell }) => (
                <Typography fontSize='small' variant='body2'>{cell.row.original.nama_kel}</Typography>
            ),
            filterSelectOptions: listKelFilter,
            filterVariant: 'select',
        },
        {
            accessorKey: 'sumber_data_username',
            header: 'Sumber Data',
            Cell: ({ cell }) => (
                <Typography fontSize='small' variant='body2'>{cell.row.original.sumber_data_nama}</Typography>
            ),
            filterSelectOptions: listPjFilter,
            filterVariant: 'select',
        },
    ], [listKecFilter, listKelFilter, listPjFilter])

    const columnVisibility = {
        no_kk: false,
        tempat_lahir: false,
        tgl_lahir: false,
    }

    return (
        <SoftBox my={3}>
            <Grid container spacing={3} mb={3} justifyContent='center'>
                <Grid item xs={12} lg={12}>
                    <MiniStatisticsCard
                        title={{ text: "Total Data" }}
                        count={rowCount}
                        // percentage={{ color: "success", text: "+55%" }}
                        icon={{ color: "info", component: "paid" }}
                    />
                </Grid>
            </Grid>

            <Grid container spacing={3} justifyContent='center'>
                <Grid item xs={12} lg={12}>
                    <Card>
                        <CardHeader
                            title={
                                <SoftTypography color='text'>
                                    Tabel Suara Tambahan
                                </SoftTypography>
                            }
                        />
                        <CardContent>
                            <Grid container justifyContent="space-between" >
                                <Grid item xs={12}>
                                    <ServerSideTable
                                        loading={loading}
                                        data={tableData}
                                        columns={columns}
                                        rowCount={rowCount}
                                        baseUrl="/admin/pemilih"
                                        pagination={pagination}
                                        setPagination={setPagination}
                                        columnFilter={columnFilter}
                                        setColumnFilter={setColumnFilter}
                                        columnVisibility={columnVisibility}
                                    />
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </SoftBox>
    )
}

export default SuaraTambahan