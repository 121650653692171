import { HighchartsReact } from 'highcharts-react-official'
import Highcharts from 'highcharts'
import drilldown from "highcharts/modules/drilldown.js";
import { useEffect, useState } from 'react';
import axios from 'axios';
import { serverUrl } from 'global/variable';
import { CustomDescrypt } from 'global/func';
import userStore from 'store/userStore';
import Swal from 'sweetalert2';
import { useSoftUIController } from 'context';
import { getDataWithResponse } from 'global/getFunc';

require("highcharts/modules/exporting")(Highcharts);
drilldown(Highcharts)

const BarDrilldown = ({ kecamatans = [] }) => {
    const [controller] = useSoftUIController();
    const { darkMode } = controller;
    const { user, addReq } = userStore(state => ({ user: state.user, addReq: state.addReq }));
    const decryptedUser = (user === null || user === undefined || user === '') ? "" : JSON.parse(CustomDescrypt(user));

    const [data, setData] = useState([]);
    // let [mapData, setMapData] = useState([]);
    const [options, setOptions] = useState({})

    useEffect(() => {
        let dtx = []
        kecamatans.forEach((kecamatan, index) => {

            let dt = {
                id: kecamatan.id_kec,
                y: kecamatan.perolehan,
                dpt: kecamatan.dpt,
                target: kecamatan.target,
                persen: (kecamatan.perolehan/kecamatan.target) * 100,
                name: kecamatan.nama_kec,
                drilldown: kecamatan.id_kec
            }
            dtx[index] = dt;
        });
        setData(dtx)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [kecamatans])

    useEffect(() => {
        setOptions({
            chart: {
                type: 'column',
                backgroundColor: darkMode ? '#111c44' : '#ffffff',
                events: {
                    drilldown: function (e) {
                        if (!e.seriesOptions) {
                            console.log(e);
                            var chart = this

                            chart.showLoading('Memuat Data'); // Font Awesome spinner

                            addReq()
                            getDataWithResponse(
                                '/kecamatan/perolehan/' + e.point.id,
                                decryptedUser,
                                (response) => {
                                    console.log(response);
                                    let dtx = []
                                    let kelurahans = response;
                                    kelurahans.forEach((kel, index) => {

                                        let dt = {
                                            name: kel.nama_kel,
                                            y: kel.perolehan,
                                            target: kel.target,
                                            persen: (kel.perolehan/kel.target) * 100,
                                        }
                                        dtx[index] = dt;
                                    });
                                    // setData(dtx)
                                    console.log(dtx);
                                    chart.hideLoading();
                                    clearTimeout();
                                    chart.addSeriesAsDrilldown(e.point, {
                                        name: e.point.name,
                                        data: dtx,
                                        dataLabels: {
                                            enabled: true,
                                            format: "{point.y}"
                                        }
                                    });
                                },
                                (error) => {
                                    chart.showLoading(
                                        'Gagal memuat Data ' + e.point.name
                                    );
                                    chart.hideLoading();
                                }
                            );
                        }

                        this.setTitle(null, { text: e.point.name });
                    },
                    drillup: function () {
                        this.setTitle(null, { text: "" });
                    },
                },
            },
            // style: {
            //     color: 'red',
            // },
            title: {
                align: 'center',
                text: 'Perolehan Suara Per Kecamatan',
                style: {
                    color: darkMode ? '#ffffff' : '#67748e'
                }
                // margin: 35
            },
            subtitle: {
                align: 'center',
                text: 'Klik kecamatan untuk melihat Data Kelurahan',
                style: {
                    color: darkMode ? '#ffffff' : '#67748e'
                }
            },
            accessibility: {
                announceNewData: {
                    enabled: true
                }
            },
            xAxis: {
                type: 'category',
                labels: {
                    style: {
                        color: darkMode ? '#ffffff' : '#67748e'
                    }
                }
            },
            yAxis: {
                title: {
                    text: 'Perolehan',
                    style: {
                        color: darkMode ? '#ffffff' : '#67748e'
                    }
                },
                gridLineWidth: 0,
                // lineColor: 'transparent',
                // minorTickLength: 0,
                // tickLength: 0
                labels: {
                    style: {
                        color: darkMode ? '#ffffff' : '#67748e'
                    }
                }
            },
            legend: {
                enabled: false
            },
            credits: {
                enabled: false
            },
            exporting: {
                enabled: true,
            },
            // navigation: {
            //     buttonOptions: {
            //         align: 'right',
            //         verticalAlign: 'top',
            //         y: 0
            //     }
            // },
            plotOptions: {
                series: {
                    borderWidth: 0,
                    // pointWidth: 25,
                    // borderRadius: 5,
                    dataLabels: {
                        enabled: true,
                        // format: '{point.y:.1f}%'
                        format: '{point.y:.f}',
                    }
                }
            },

            tooltip: {
                // shared: true,
                // // headerFormat: '<span style="font-size:11px">{point.name}</span><br>',
                // // pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.2f}%</b> of total<br/>'
                // pointFormat: '<span style="color:{point.color}">Perolehan</span>: <b>{point.y}</b></br>'+
                //                 '<span style="color:{point.color}">Target</span>: <b>{point.target}</b></br>'
                useHtml: true,
                // pointFormat: '{point.y:,.0f}',
                formatter: function () {
                    return (
                        `<b>${this.point.name}</b></br>
                        Perolehan ${Highcharts.numberFormat(this.point.y, 0, ',', '.')} (${Highcharts.numberFormat(this.point.persen, 2, ',', '.')}%)</br>
                        Target ${Highcharts.numberFormat(this.point.target, 0, ',', '.')}`
                    )
                }
            },
            series: [
                {
                    name: 'Kota Kendari',
                    colorByPoint: true,
                    data: data
                },
            ],
            drilldown: {
                breadcrumbs: {
                    position: {
                        align: 'left'
                    }
                },
                activeAxisLabelStyle: {
                    textDecoration: 'none',
                    color: darkMode ? '#ffffff' : '#67748e',
                    // fontStyle: 'italic',
                    fontWeight: 'normal'
                },
                activeDataLabelStyle: {
                    textDecoration: 'none',
                    color: darkMode ? '#ffffff' : '#67748e'
                    // fontWeight: 'normal'
                },
                series: []
            }
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, darkMode])

    return (
        <HighchartsReact
            highcharts={Highcharts}
            options={options}
        />
    )
}

export default BarDrilldown