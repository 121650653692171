/**
=========================================================
* Soft UI Dashboard React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Soft UI Dashboard React base styles
import colors from "assets/theme/base/colors";

// Soft UI Dashboard React helper functions
import rgba from "assets/theme/functions/rgba";
import pxToRem from "assets/theme/functions/pxToRem";

const { dark, transparent, white } = colors;

const divider = {
    styleOverrides: {
        root: {
            backgroundColor: transparent.main,
            backgroundImage: `linear-gradient(to right, ${rgba(dark.main, 0.1)}, ${rgba(
                dark.main,
                0.5
            )}, ${rgba(dark.main, 0.1)}) !important`,
            height: pxToRem(1),
            margin: `${pxToRem(16)} 0`,
            borderBottom: "none",
            opacity: 0.25,
        },

        vertical: {
            backgroundColor: transparent.main,
            backgroundImage: `linear-gradient(to bottom, ${rgba(dark.main, 0.5)}, ${rgba(
                dark.main,
                0.8
            )}, ${rgba(dark.main, 0.5)}) !important`,
            width: pxToRem(2),
            height: "100%",
            margin: `0 0 0 0`,
            borderRight: "none",
        },

        light: {
            backgroundColor: transparent.main,
            backgroundImage: `linear-gradient(to right, ${rgba(white.main, 0)}, ${rgba(
                white.main,
                0.5
            )}, ${rgba(white.main, 0)}) !important`,

            "&.MuiDivider-vertical": {
                backgroundImage: `linear-gradient(to bottom, ${rgba(white.main, 0)}, ${rgba(
                    white.main,
                    0.5
                )}, ${rgba(white.main, 0)}) !important`,
            },
        },
    },
};

export default divider;
