import { ArrowBack, Save } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { Box, Card, CardContent, CardHeader, CircularProgress, Grid, IconButton, Tooltip } from '@mui/material'
import axios from 'axios'
import Headerx from 'components/Header/Headerx'
import SoftBox from 'components/SoftBox'
import SoftInput from 'components/SoftInput'
import SoftTypography from 'components/SoftTypography'
import { CustomDescrypt } from 'global/func'
import { serverUrl } from 'global/variable'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import userStore from 'store/userStore'
import Swal from 'sweetalert2'

const Tambah = () => {
    const navigate = useNavigate();
    const encryptUser = localStorage.getItem('AuthPersist');
    const encryptUserJson = JSON.parse(encryptUser);
    const decryptedUser = encryptUserJson.state.user === null ? "" : JSON.parse(CustomDescrypt(encryptUserJson.state.user));
    const logOut = userStore((state) => state.logOut)
    // const decodedUser = decryptedUser === '' ? '' : decodeToken(decryptedUser);

    const [loading, setLoading] = useState(false)
    // const [jenisApks, setJenisApks] = useState([])
    const [formData, setFormData] = useState({
        username: '',
        password: '',
        nama: '',
        no_hp: ''
    })

    useEffect(() => {
        console.log(formData);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formData])

    const submit = (event) => {
        event.preventDefault();
        setLoading(true)
        // setFormData({...formData, agg})
        axios.post(
            serverUrl + '/admin/thi_tim/insert',
            formData,
            {
                headers: {
                    'Authorization': `Bearer ${decryptedUser}`,
                    'content-type': 'multipart/form-data'
                }
            })
            .then((response) => {
                console.log(response);
                Swal.fire({
                    icon: 'success',
                    title: 'Berhasil!',
                    text: response.data.msg
                })
                navigate(-1)
                setLoading(false)
            })
            .catch((error) => {
                console.log(error);
                if (error.response.status === 401 && error.response.data.messages === "Access Token Expired") {
                    logOut();
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: error.response.data.messages.msg
                    })
                }
                setLoading(false)
            })
    }

    return (
        <>
            <Headerx title='Admin' />
            <SoftBox mb={3}>
                <Grid container spacing={3} justifyContent='center' py={5}>
                    <Grid item xs={12} sm={12} md={9}>
                        <Card >
                            <CardHeader
                                title={
                                    <SoftTypography variant='h6'>Kembali</SoftTypography>
                                }
                                avatar={
                                    <Tooltip title="Kembali">
                                        <IconButton variant="outlined" onClick={() => navigate(-1)}><ArrowBack /></IconButton>
                                    </Tooltip>
                                }
                            />
                            {
                                loading ?
                                    (
                                        <Grid container spacing={3} py={5} justifyContent="center">
                                            <Grid item>
                                                <Box sx={{ display: 'flex' }}>
                                                    <CircularProgress />
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    )
                                    :
                                    (
                                        <CardContent component='form' onSubmit={submit}>
                                            <Grid container justifyContent='center' direction='column'>
                                                <Grid item xs={12} sm={12} lg={6}>
                                                    <SoftBox mb={2} justifyContent="center">
                                                        <SoftBox mb={1} ml={0.5}>
                                                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                                Username
                                                            </SoftTypography>
                                                        </SoftBox>
                                                        <SoftInput
                                                            type="text"
                                                            defaultValue={formData.username}
                                                            onChange={(event) => setFormData({ ...formData, username: event.target.value })}
                                                            autoComplete='off'
                                                        />
                                                    </SoftBox>
                                                </Grid>
                                                <Grid item xs={12} sm={12} lg={6}>
                                                    <SoftBox mb={2} justifyContent="center">
                                                        <SoftBox mb={1} ml={0.5}>
                                                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                                Password
                                                            </SoftTypography>
                                                        </SoftBox>
                                                        <SoftInput
                                                            type="text"
                                                            defaultValue={formData.password}
                                                            onChange={(event) => setFormData({ ...formData, password: event.target.value })}
                                                            autoComplete='off'
                                                        />
                                                    </SoftBox>
                                                </Grid>
                                                <Grid item xs={12} sm={12} lg={6}>
                                                    <SoftBox mb={2} justifyContent="center">
                                                        <SoftBox mb={1} ml={0.5}>
                                                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                                Nama
                                                            </SoftTypography>
                                                        </SoftBox>
                                                        <SoftInput
                                                            type="text"
                                                            defaultValue={formData.nama}
                                                            onChange={(event) => setFormData({ ...formData, nama: event.target.value })}
                                                            autoComplete='off'
                                                        />
                                                    </SoftBox>
                                                </Grid>
                                                <Grid item xs={12} sm={12} lg={6}>
                                                    <SoftBox mb={2} justifyContent="center">
                                                        <SoftBox mb={1} ml={0.5}>
                                                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                                No. HP
                                                            </SoftTypography>
                                                        </SoftBox>
                                                        <SoftInput
                                                            type="text"
                                                            defaultValue={formData.no_hp}
                                                            onChange={(event) => setFormData({ ...formData, no_hp: event.target.value })}
                                                            autoComplete='off'
                                                        />
                                                    </SoftBox>
                                                </Grid>
                                                
                                                <Grid item xs={12} sm={12} lg={6}>
                                                    <Grid container justifyContent='flex-end'>
                                                        <Grid item>
                                                            <SoftBox mb={1} ml={0.5}>
                                                                <LoadingButton
                                                                    loading={loading}
                                                                    loadingPosition="start"
                                                                    startIcon={<Save />}
                                                                    variant="contained"
                                                                    type='submit'
                                                                >
                                                                    Save
                                                                </LoadingButton>
                                                            </SoftBox>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    )
                            }

                        </Card>
                    </Grid>
                </Grid>
            </SoftBox>
        </>
    )
}

export default Tambah