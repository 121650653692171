// react-router-dom components
import { Link } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import { Breadcrumbs as MuiBreadcrumbs } from "@mui/material";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

function Breadcrumbs({ icon, title, route, light, home }) {
    const routes = route.slice(1, -1);
    //   console.log(title);

    return (
        <SoftBox mr={{ xs: 0, xl: 8 }}>
            <MuiBreadcrumbs
                sx={{
                    "& .MuiBreadcrumbs-separator": {
                        // color: ({ palette: { white, grey } }) => (light ? white.main : grey[600]),
                        color: ({ palette: { white, grey } }) => (light ? white.main : grey[600])
                    }
                }}
            >
                <Link to={home}>
                    <SoftTypography
                        component="span"
                        variant="body2"
                        color='text'
                        // opacity={light ? 0.8 : 0.5}
                        sx={{
                            lineHeight: 0,
                            // color: ({ palette: { white, grey } }) => (light ? white.main : grey[600])
                        }}
                    >
                        <Icon>{icon}</Icon>
                    </SoftTypography>
                </Link>
                {routes.map((el, index) => (
                    <SoftTypography
                        key={index}
                        component="span"
                        variant="button"
                        fontWeight="regular"
                        textTransform="capitalize"
                        color="text"
                        opacity={light ? 0.8 : 0.5}
                        sx={{ lineHeight: 0 }}
                    >
                        {el}
                    </SoftTypography>
                ))}
                <SoftTypography
                    variant="button"
                    fontWeight="regular"
                    textTransform="capitalize"
                    color="text"
                    sx={{ lineHeight: 0 }}
                >
                    {title.replace("-", " ")}
                </SoftTypography>
            </MuiBreadcrumbs>
            {/* <SoftTypography
                fontWeight="bold"
                textTransform="capitalize"
                variant="h6"
                color={light ? "white" : "dark"}
                noWrap
            >
                {title.replace("-", " ")}
            </SoftTypography> */}
        </SoftBox>
    );
}

// Setting default values for the props of Breadcrumbs
Breadcrumbs.defaultProps = {
    light: false,
};

// Typechecking props for the Breadcrumbs
Breadcrumbs.propTypes = {
    icon: PropTypes.node.isRequired,
    title: PropTypes.string.isRequired,
    route: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
    light: PropTypes.bool,
};

export default Breadcrumbs;
