import Configurator from 'components/Configurator'
import DashboardLayout from 'components/DashboardLayout'
import DashboardNavbar from 'components/DashboardNavbar'
import Sidenav from 'components/Sidenav'
import React, { useEffect, useState } from 'react'
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import { Settings } from '@mui/icons-material'

import logo from 'assets/images/logo.png'
import { CustomDescrypt } from 'global/func'
import userStore from 'store/userStore'
import { useSoftUIController } from 'context'
import { decodeToken, isExpired } from 'react-jwt'
import { setMiniSidenav } from 'context'
import { setOpenConfigurator } from 'context'
import SoftBox from 'components/SoftBox'
import Pemilih from './Pemilih'
import entryRoutes from './entryRoutes'
import DTD from './DTD'
import Footer from 'components/Footer'
import Tim from './Tim'
import PertemuanTerbatas from './Pt'
import SuaraTambahan from './SuaraTambahan'
import Bansos from './Bansos'
import Headerx from 'components/Header/Headerx'

const TemplateEntry = () => {
    const { user, reqCount } = userStore(state => ({ user: state.user, reqCount: state.reqCount }));
    const logOut = userStore((state) => state.logOut)
    const decryptedUser = (user === null || user === undefined || user === '') ? "" : JSON.parse(CustomDescrypt(user));
    const [decodedUser] = useState(decodeToken(decryptedUser));
    
    const navigate = useNavigate();
    const location = useLocation();
    const [controller, dispatch] = useSoftUIController();
    const { miniSidenav, direction, openConfigurator, sidenavColor } = controller;
    const [onMouseEnter, setOnMouseEnter] = useState(false);

    useEffect(() => {
        // console.log(user);
        if (user === null) {
            navigate("/", { replace: true });
        }
        else {
            if (isExpired(decryptedUser)) {
                console.log(isExpired(decryptedUser));
                logOut()
                navigate("/", { replace: true });
            }
            else {
                if (decodedUser.role !== 'entry') {
                    navigate("/", { replace: true });
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user, location, reqCount])

    const handleOnMouseEnter = () => {
        if (miniSidenav && !onMouseEnter) {
            setMiniSidenav(dispatch, false);
            setOnMouseEnter(true);
        }
    };

    // Close sidenav when mouse leave mini sidenav
    const handleOnMouseLeave = () => {
        if (onMouseEnter) {
            setMiniSidenav(dispatch, true);
            setOnMouseEnter(false);
        }
    };

    // Change the openConfigurator state
    const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

    // Setting the dir attribute for the body element
    useEffect(() => {
        document.body.setAttribute("dir", direction);
    }, [direction]);

    // Setting page scroll to 0 when changing the route
    useEffect(() => {
        document.documentElement.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;
    }, [location]);

    const configsButton = (
        <SoftBox
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="3.5rem"
            height="3.5rem"
            bgColor="white"
            shadow="sm"
            borderRadius="50%"
            position="fixed"
            right="2rem"
            bottom="2rem"
            zIndex={99}
            color="dark"
            sx={{ cursor: "pointer" }}
            onClick={handleConfiguratorOpen}
        >
            <Settings fontSize="default" color="inherit" />
        </SoftBox>
    );

    return (
        <>
            <Sidenav
                color={sidenavColor}
                brand={logo}
                brandName=""
                routes={entryRoutes}
                onMouseEnter={handleOnMouseEnter}
                onMouseLeave={handleOnMouseLeave}
                homePage='/entry' />

            <Configurator />
            {configsButton}

            <DashboardLayout>
                <DashboardNavbar homePage='/entry' isMini={miniSidenav} loginId={decodedUser?.id || ''} />
                <Headerx title='Entry' />
                <Routes>
                    <Route exact path="" element={<Navigate to="/entry/pemilih" />} />
                    <Route exact path="pemilih/*" element={<Pemilih />} />
                    <Route exact path="dtd/*" element={<DTD />} />
                    <Route exact path="tim/*" element={<Tim />} />
                    <Route exact path="pt/*" element={<PertemuanTerbatas />} />
                    <Route exact path="bansos/*" element={<Bansos />} />
                    <Route exact path="suara-tambahan/*" element={<SuaraTambahan />} />

                </Routes>
                <Footer />
            </DashboardLayout>
        </>
    )
}

export default TemplateEntry