import axios from "axios"
import { serverUrl } from "./variable"
import Swal from "sweetalert2"

export const getDataWithResponse = (
    url,
    decryptedUser,
    responseHandling,
    errorHandler
) => {
    axios.get(
        serverUrl + url,
        {
            headers: {
                'Authorization': `Bearer ${decryptedUser}`,
                'content-type': 'multipart/form-data'
            }
        })
        .then((response) => {
            // console.log(response);
            responseHandling(response.data)
        })
        .catch((error) => {
            errorHandler && errorHandler(error);
            console.log(error);
            Swal.fire({
                icon: 'error',
                title: error.response.status,
                text: error.response.data.message || error.response.data
            })
        })
}