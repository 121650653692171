import { ArrowBack } from '@mui/icons-material'
import { Card, CardContent, CardHeader, Grid, IconButton, Tooltip } from '@mui/material'
import SoftBox from 'components/SoftBox'
import SoftTypography from 'components/SoftTypography'
import DetailDpt from 'myComponent/DetailDpt'
import { useLocation, useNavigate } from 'react-router-dom'

const Detail = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const data = location.state;

    return (
        <SoftBox mb={3}>
            <Grid container spacing={3} justifyContent='center' py={5}>
                <Grid item xs={12} sm={12} md={9}>
                    <Card>
                        <CardHeader
                            title={
                                <SoftTypography variant='h6'>Kembali</SoftTypography>
                            }
                            avatar={
                                <Tooltip title="Kembali">
                                    <IconButton variant="outlined" color='text' onClick={() => navigate(-1)}><ArrowBack /></IconButton>
                                </Tooltip>
                            }
                        // subheader="September 14, 2016"
                        />
                        <CardContent>
                            <Grid container spacing={2} justifyContent='center'>
                                <Grid item xs={12}>
                                    <DetailDpt data={data} />
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </SoftBox>
    )
}

export default Detail