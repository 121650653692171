import { Clear, Done } from '@mui/icons-material'
import { Chip, Divider, List, ListItem, ListItemText } from '@mui/material'
import SoftTypography from 'components/SoftTypography'
import { useEffect } from 'react'

const DetailDpt = ({ data }) => {
    useEffect(() => {
        console.log(data);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <List sx={{ width: '100%' }}>
            <ListItem>
                <ListItemText primary={
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                        NIK
                    </SoftTypography>
                }
                    secondary={
                        <SoftTypography component="label" variant="body2">
                            {data.nik ? data.nik : '-'}
                        </SoftTypography>
                    } />
            </ListItem>
            <Divider />
            <ListItem>
                <ListItemText primary={
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                        No. KK
                    </SoftTypography>
                }
                    secondary={
                        <SoftTypography component="label" variant="body2">
                            {data.no_kk ? data.no_kk : '-'}
                        </SoftTypography>
                    } />
            </ListItem>
            <Divider />
            <ListItem>
                <ListItemText primary={
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                        Nama
                    </SoftTypography>
                }
                    secondary={
                        <SoftTypography component="label" variant="body2">
                            {data.nama ? data.nama : '-'}
                        </SoftTypography>
                    } />
            </ListItem>
            <Divider />
            <ListItem>
                <ListItemText
                    primary={
                        <SoftTypography component="label" variant="caption" fontWeight="bold">
                            Tempat/Tgl Lahir
                        </SoftTypography>
                    }
                    secondary={
                        <SoftTypography component="label" variant="body2">
                            {data.tempat_lahir ? data.tempat_lahir + ', ' + data.tgl_lahir : '-'}
                        </SoftTypography>
                    }
                />
            </ListItem>
            <Divider />
            <ListItem>
                <ListItemText primary={
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                        Kecamatan
                    </SoftTypography>
                } secondary={
                    <SoftTypography component="label" variant="body2">
                        {data.nama_kec}
                    </SoftTypography>
                } />
            </ListItem>
            <Divider variant="inset" component="li" />
            <ListItem>
                <ListItemText primary={
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                        Kelurahan
                    </SoftTypography>
                } secondary={
                    <SoftTypography component="label" variant="body2">
                        {data.nama_kel}
                    </SoftTypography>
                } />
            </ListItem>
            <Divider variant="inset" component="li" />
            <ListItem>
                <ListItemText primary={
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                        Alamat
                    </SoftTypography>
                } secondary={
                    <SoftTypography component="label" variant="body2">
                        {data.alamat}
                    </SoftTypography>
                } />
            </ListItem>
            <Divider variant="inset" component="li" />
            {
                data.tim !== '' ?
                    (
                        <>
                            <ListItem>
                                <ListItemText primary={
                                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                                        Status
                                    </SoftTypography>
                                } secondary={
                                    <Chip label={data.tim} color='primary' />
                                } />
                            </ListItem>
                            <Divider variant="inset" component="li" />
                        </>
                    )
                    :
                    (
                        <>
                            <ListItem>
                                <ListItemText primary={
                                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                                        Status
                                    </SoftTypography>
                                } secondary={
                                    <SoftTypography component="label" variant="body2" sx={{ display: 'block' }}>
                                        {data.status}
                                    </SoftTypography>
                                } />
                            </ListItem>
                            <Divider variant="inset" component="li" />
                            <ListItem>
                                <ListItemText primary={
                                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                                        Sumber Data
                                    </SoftTypography>
                                } secondary={
                                    <SoftTypography component="label" variant="body2" sx={{ display: 'block' }}>
                                        {data.sumber_data_nama}
                                    </SoftTypography>
                                } />
                            </ListItem>
                            <Divider variant="inset" component="li" />
                            <ListItem>
                                <ListItemText primary={
                                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                                        Pertemuan Terbatas
                                    </SoftTypography>
                                } secondary={
                                    data.pertemuan_terbatas === '' ? (<Clear fontSize='large' color='error' />) : (<Done fontSize='large' color='success' />)
                                } />
                            </ListItem>
                            <Divider variant="inset" component="li" />
                        </>
                    )
            }
        </List>
    )
}

export default DetailDpt