// @mui material components
import Divider from "@mui/material/Divider";
import Switch from "@mui/material/Switch";
import IconButton from "@mui/material/IconButton";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";

// Custom styles for the Configurator
import ConfiguratorRoot from "./ConfiguratorRoot";

// Soft UI Dashboard React context
import {
    useSoftUIController,
    setOpenConfigurator,
    setDarkSidenav,
    setMiniSidenav,
    setFixedNavbar,
    setSidenavColor,
    setDarkMode
} from "context";
import { Close } from "@mui/icons-material";

function Configurator() {
    const [controller, dispatch] = useSoftUIController();
    const { openConfigurator, fixedNavbar, sidenavColor, miniSidenav, darkSidenav, darkMode } = controller;
    const sidenavColors = ["primary", "secondary", "info", "success", "warning", "error", "dark"];

    const handleCloseConfigurator = () => setOpenConfigurator(dispatch, false);
    const handledarkSidenav = () => setDarkSidenav(dispatch, true);
    const handleWhiteSidenav = () => setDarkSidenav(dispatch, false);
    const handleFixedNavbar = () => setFixedNavbar(dispatch, !fixedNavbar);
    const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
    const handleDarkMode = () => {
        setDarkSidenav(dispatch, !darkMode);
        setDarkMode(dispatch, !darkMode);
    };

    // setMiniSidenav(dispatch, true)

    // sidenav type buttons styles
    // const sidenavTypeButtonsStyles = ({
    //     functions: { pxToRem },
    //     boxShadows: { buttonBoxShadow },
    // }) => ({
    //     height: pxToRem(42),
    //     boxShadow: buttonBoxShadow.main,

    //     "&:hover, &:focus": {
    //         opacity: 1,
    //     },
    // });

    return (
        <ConfiguratorRoot variant="permanent" ownerState={{ openConfigurator }}>
            <SoftBox
                display="flex"
                justifyContent="space-between"
                alignItems="baseline"
                pt={3}
                pb={0.8}
                px={3}
            >
                <SoftBox>
                    <SoftTypography variant="h5">Configurator</SoftTypography>
                    <SoftTypography variant="body2" color="text">
                        See our dashboard options.
                    </SoftTypography>
                </SoftBox>

                <Close
                    sx={({ typography: { size, fontWeightBold }, palette: { dark } }) => ({
                        fontSize: `${size.md} !important`,
                        fontWeight: `${fontWeightBold} !important`,
                        stroke: dark.main,
                        strokeWidth: "2px",
                        cursor: "pointer",
                        mt: 2,
                    })}
                    onClick={handleCloseConfigurator}
                />
            </SoftBox>

            <Divider />

            <SoftBox pt={1.25} pb={3} px={3}>
                <SoftBox>
                    <SoftTypography variant="h6">Sidenav Colors</SoftTypography>

                    <SoftBox mb={0.5}>
                        {sidenavColors.map((color) => (
                            <IconButton
                                key={color}
                                sx={({ borders: { borderWidth }, palette: { white, dark }, transitions }) => ({
                                    width: "24px",
                                    height: "24px",
                                    padding: 0,
                                    border: `${borderWidth[1]} solid ${white.main}`,
                                    borderColor: sidenavColor === color && dark.main,
                                    transition: transitions.create("border-color", {
                                        easing: transitions.easing.sharp,
                                        duration: transitions.duration.shorter,
                                    }),
                                    backgroundImage: ({ functions: { linearGradient }, palette: { gradients } }) =>
                                        linearGradient(gradients[color].main, gradients[color].state),

                                    "&:not(:last-child)": {
                                        mr: 1,
                                    },

                                    "&:hover, &:focus, &:active": {
                                        borderColor: dark.main,
                                    },
                                })}
                                onClick={() => setSidenavColor(dispatch, color)}
                            />
                        ))}
                    </SoftBox>
                </SoftBox>

                <SoftBox mt={3} lineHeight={1}>
                    <SoftTypography variant="h6">Sidenav Type</SoftTypography>
                    <SoftTypography variant="button" color="text" fontWeight="regular">
                        Choose between 2 different sidenav types.
                    </SoftTypography>

                    <SoftBox
                        sx={{
                            display: "flex",
                            mt: 2,
                        }}
                    >
                        <SoftButton
                            color="info"
                            variant={darkSidenav ? "outlined" : "gradient"}
                            onClick={handleWhiteSidenav}
                            fullWidth
                        >
                            White
                        </SoftButton>
                        <SoftButton
                            color="info"
                            variant={darkSidenav ? "gradient" : "outlined"}
                            onClick={handledarkSidenav}
                            fullWidth
                            sx={{
                                ml: 1,
                            }}
                        >
                            Dark
                        </SoftButton>
                    </SoftBox>
                </SoftBox>
                <SoftBox mt={3} mb={2} lineHeight={1}>
                    <SoftTypography variant="h6">Navbar Fixed</SoftTypography>

                    <Switch checked={fixedNavbar} onChange={handleFixedNavbar} />
                </SoftBox>

                <Divider />

                <SoftBox mt={3} mb={2} lineHeight={1}>
                    <SoftTypography variant="h6">Mini Sidenav</SoftTypography>

                    <Switch checked={miniSidenav} onChange={handleMiniSidenav} />
                </SoftBox>
                
                <SoftBox mt={3} mb={2} lineHeight={1}>
                    <SoftTypography variant="h6">Light/Dark</SoftTypography>

                    <Switch checked={darkMode} onChange={handleDarkMode} />
                </SoftBox>

                <Divider />

                {/* <SoftBox mt={3} mb={2}>
                    <SoftBox mb={2}>
                        <SoftButton
                            component={Link}
                            href="https://www.creative-tim.com/product/soft-ui-dashboard-react"
                            target="_blank"
                            rel="noreferrer"
                            color="dark"
                            variant="gradient"
                            fullWidth
                        >
                            free download
                        </SoftButton>
                    </SoftBox>
                    <SoftButton
                        component={Link}
                        href="https://www.creative-tim.com/learning-lab/react/quick-start/soft-ui-dashboard/"
                        target="_blank"
                        rel="noreferrer"
                        color="dark"
                        variant="outlined"
                        fullWidth
                    >
                        view documentation
                    </SoftButton>
                </SoftBox>
                <SoftBox display="flex" justifyContent="center">
                    <a
                        className="github-button"
                        href="https://github.com/creativetimofficial/soft-ui-dashboard-react"
                        data-icon="octicon-star"
                        data-size="large"
                        data-show-count="true"
                        aria-label="Star creativetimofficial/soft-ui-dashboard-react on GitHub"
                    >
                        Star
                    </a>
                </SoftBox>
                <SoftBox mt={3} textAlign="center">
                    <SoftBox mb={0.5}>
                        <SoftTypography variant="h6">Thank you for sharing!</SoftTypography>
                    </SoftBox>

                    <SoftBox display="flex" justifyContent="center">
                        <SoftBox mr={1.5}>
                            <SoftButton
                                component={Link}
                                href="//twitter.com/intent/tweet?text=Check%20Soft%20UI%20Dashboard%20React%20made%20by%20%40CreativeTim%20%23webdesign%20%23dashboard%20%23react%23mui&url=https%3A%2F%2Fwww.creative-tim.com%2Fproduct%2Fsoft-ui-dashboard-react"
                                target="_blank"
                                rel="noreferrer"
                                color="dark"
                            >
                                <TwitterIcon />
                                &nbsp; Tweet
                            </SoftButton>
                        </SoftBox>
                        <SoftButton
                            component={Link}
                            href="https://www.facebook.com/sharer/sharer.php?u=https://www.creative-tim.com/product/soft-ui-dashboard-react"
                            target="_blank"
                            rel="noreferrer"
                            color="dark"
                        >
                            <FacebookIcon />
                            &nbsp; Share
                        </SoftButton>
                    </SoftBox>
                </SoftBox> */}
            </SoftBox>
        </ConfiguratorRoot>
    );
}

export default Configurator;
