import { ArrowBack, Save } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Autocomplete, Box, Card, CardContent, CardHeader, CircularProgress, Divider, Grid, IconButton, List, ListItem, ListItemText, ListSubheader, TextField, Tooltip, Typography } from '@mui/material';
import Headerx from 'components/Header/Headerx';
import SoftBox from 'components/SoftBox';
import SoftTypography from 'components/SoftTypography';
import { CustomDescrypt } from 'global/func';
import { postSubmit } from 'global/postFunc';
import { postDataWithResponse } from 'global/postFunc';
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import userStore from 'store/userStore';

const TambahPenerima = () => {
    const encryptUser = localStorage.getItem('AuthPersist');
    const encryptUserJson = JSON.parse(encryptUser);
    const decryptedUser = encryptUserJson.state.user === null ? "" : JSON.parse(CustomDescrypt(encryptUserJson.state.user));
    const logOut = userStore((state) => state.logOut)

    const location = useLocation();
    const navigate = useNavigate();
    const data = location.state;

    const [loading, setLoading] = useState(false)
    const [listDpt, setListDpts] = useState([])
    const [dpt, setDpt] = useState({})

    // const [input, setInput] = useState('');

    useEffect(() => {
        reqDpt()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        // console.log(formData);
        console.log(data);
    }, [data])


    const reqDpt = () => {
        postDataWithResponse(
            '/dpt',
            {
                bansos: '',
                id_kec: data.id_kec,
                id_kel: data.id_kel,
                tim: ''
            },
            decryptedUser,
            setLoading,
            setListDpts,
            logOut
        )
    }

    const submit = (event) => {
        event.preventDefault();
        postSubmit(
            '/entry/bansos/update-penerima',
            {
                _method: 'PUT',
                nik: dpt.nik,
                bansos: data.id_bansos
            },
            decryptedUser,
            setLoading,
            () => { navigate(-1) },
            logOut
        );
    }

    return (
        <>
            <Headerx title='Tambah Data' />
            <SoftBox mb={3}>
                <Grid container spacing={3} justifyContent='center' py={5}>
                    <Grid item xs={12} sm={12} md={9}>
                        <Card >
                            <CardHeader
                                title={
                                    <SoftTypography variant='h6'>Kembali</SoftTypography>
                                }
                                avatar={
                                    <Tooltip title="Kembali">
                                        <IconButton variant="outlined" onClick={() => navigate(-1)}><ArrowBack /></IconButton>
                                    </Tooltip>
                                }
                            />
                            {
                                loading ?
                                    (
                                        <Grid container spacing={3} py={5} justifyContent="center">
                                            <Grid item>
                                                <Box sx={{ display: 'flex' }}>
                                                    <CircularProgress />
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    )
                                    :
                                    (
                                        <CardContent component='form' onSubmit={submit}>
                                            <Grid container spacing={3} justifyContent="center">
                                                <Grid item xs={12} sm={12} lg={6}>
                                                    <SoftBox mb={1} ml={0.5}>
                                                        <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                            NIK
                                                        </SoftTypography>
                                                    </SoftBox>
                                                    <Autocomplete
                                                        disablePortal
                                                        id="combo-box-demo"
                                                        options={listDpt}
                                                        fullWidth
                                                        getOptionLabel={(option) => option.nik + ' ' + option.nama}
                                                        onChange={(event, newValue) => {
                                                            newValue !== null && setDpt(newValue)
                                                        }}
                                                        onInputChange={(event, newInputValue) => {
                                                            newInputValue === "" && setDpt({})
                                                            // newInputValue.length > 7 && reqDpt()
                                                        }}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                            // onChange={(event) => setInput(event.target.value)}
                                                            />
                                                        )}
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={3} py={3} justifyContent="center">

                                                <Grid item xs={12} sm={12} lg={6}>
                                                    <List sx={{ bgcolor: 'background.paper' }} subheader={
                                                        <ListSubheader component="div" id="nested-list-subheader">
                                                            Data DPT
                                                        </ListSubheader>
                                                    }>
                                                        <Divider />
                                                        <ListItem>
                                                            <ListItemText primary={
                                                                <Typography
                                                                    variant="body2"
                                                                    color="text.primary"
                                                                >
                                                                    NIK
                                                                </Typography>
                                                            }
                                                                secondary={
                                                                    <Typography
                                                                        variant="body2"
                                                                        color="text.primary"
                                                                    >
                                                                        {dpt.nik ? dpt.nik : '-'}
                                                                    </Typography>
                                                                } />
                                                        </ListItem>
                                                        <Divider />
                                                        <ListItem>
                                                            <ListItemText primary={
                                                                <Typography
                                                                    variant="body2"
                                                                    color="text.primary"
                                                                >
                                                                    No. KK
                                                                </Typography>
                                                            }
                                                                secondary={
                                                                    <Typography
                                                                        variant="body2"
                                                                        color="text.primary"
                                                                    >
                                                                        {dpt.no_kk ? dpt.no_kk : '-'}
                                                                    </Typography>
                                                                } />
                                                        </ListItem>
                                                        <Divider />
                                                        <ListItem>
                                                            <ListItemText primary={
                                                                <Typography
                                                                    variant="body2"
                                                                    color="text.primary"
                                                                >
                                                                    Nama
                                                                </Typography>
                                                            }
                                                                secondary={
                                                                    <Typography
                                                                        variant="body2"
                                                                        color="text.primary"
                                                                    >
                                                                        {dpt.nama ? dpt.nama : '-'}
                                                                    </Typography>
                                                                } />
                                                        </ListItem>
                                                        <Divider />
                                                        <ListItem>
                                                            <ListItemText
                                                                primary={
                                                                    <Typography
                                                                        variant="body2"
                                                                        color="text.primary"
                                                                    >
                                                                        Tempat/Tgl Lahir
                                                                    </Typography>
                                                                }
                                                                secondary={
                                                                    <Typography
                                                                        variant="body2"
                                                                        color="text.primary"
                                                                    >
                                                                        {dpt.tempat_lahir ? dpt.tempat_lahir + ', ' + dpt.tgl_lahir : '-'}
                                                                    </Typography>
                                                                }
                                                            />
                                                        </ListItem>
                                                        <Divider />
                                                        <ListItem>
                                                            <ListItemText primary={
                                                                <Typography
                                                                    variant="body2"
                                                                    color="text.primary"
                                                                >
                                                                    Alamat
                                                                </Typography>
                                                            }
                                                                secondary={
                                                                    <Typography
                                                                        variant="body2"
                                                                        color="text.primary"
                                                                    >
                                                                        {dpt.alamat ? `${dpt.alamat} RT. ${dpt.rt} RW. ${dpt.rw}, Kelurahan. ${dpt.nama_kel}, Kec. ${dpt.nama_kec}, Kab/Kota. ${dpt.nama_kab}` : '-'}
                                                                    </Typography>
                                                                } />
                                                        </ListItem>
                                                        <Divider />
                                                    </List>
                                                </Grid>
                                            </Grid>
                                            <Grid container justifyContent='center'>
                                                <Grid item xs={12} sm={12} lg={6}>
                                                    <Grid container justifyContent='flex-end'>
                                                        <Grid item>
                                                            <SoftBox mb={1} ml={0.5}>
                                                                <LoadingButton
                                                                    loading={loading}
                                                                    loadingPosition="start"
                                                                    startIcon={<Save />}
                                                                    variant="contained"
                                                                    type='submit'
                                                                >
                                                                    Save
                                                                </LoadingButton>
                                                            </SoftBox>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    )
                            }

                        </Card>
                    </Grid>
                </Grid>
            </SoftBox>
        </>
    )
}

export default TambahPenerima