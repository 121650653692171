/**
=========================================================
* Argon Dashboard 2 MUI - v3.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-material-ui
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Argon Dashboard 2 MUI base styles
import colors from "assets/theme-dark/base/colors";

// Argon Dashboard 2 MUI helper functions
import pxToRem from "assets/theme-dark/functions/pxToRem";

const { transparent } = colors;

const select = {
    styleOverrides: {
        select: {
            display: "grid",
            alignItems: "center",
            //   padding: `0 ${pxToRem(12)} !important`,
            padding: `0 !important`,

            "& .Mui-selected": {
                backgroundColor: transparent.main,
            },
        },

        selectMenu: {
            background: "none",
            height: "none",
            minHeight: "none",
            overflow: "unset",
        },

        icon: {
            display: "none",
        },
    },
};

export default select;
