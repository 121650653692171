import React from 'react'
import { useSoftUIController } from '../../context';
import { ListItem, ListItemIcon, ListItemText } from '@mui/material';
import SoftBox from '../SoftBox';
import { collapseChildIcon, collapseChildIconBox, collapseChildItem, collapseText } from './styles/sidenavCollapse';
import { Stop } from '@mui/icons-material';
import { NavLink } from 'react-router-dom';

const SidenavChild = ({ color, name, active, route }) => {
    const [controller] = useSoftUIController();
    const { miniSidenav, transparentSidenav } = controller;
    return (
        <ListItem component="li">
            <NavLink to={route}>
                <SoftBox sx={(theme) => collapseChildItem(theme, { active, transparentSidenav })}>
                    <ListItemIcon
                        sx={(theme) => collapseChildIconBox(theme, { active, transparentSidenav, color })}
                    >
                        <Stop sx={(theme) => collapseChildIcon(theme, { active })} />
                    </ListItemIcon>
                    <ListItemText
                        primary={name}
                        sx={(theme) => collapseText(theme, { miniSidenav, transparentSidenav, active })}
                    />
                </SoftBox>
            </NavLink>
        </ListItem>
    )
}

SidenavChild.propTypes = {}

export default SidenavChild