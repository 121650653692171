import { useEffect, useState } from "react";

// react-router-dom components
import { useLocation, NavLink } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard React examples
import SidenavCollapse from "./SidenavCollapse";

// Custom styles for the Sidenav
import SidenavRoot from "./SidenavRoot";
import sidenavLogoLabel from "./styles/sidenav";

// Soft UI Dashboard React context
import { useSoftUIController, setMiniSidenav } from "context";
import SidenavChild from "./SidenavChild";
import { ThemeProvider } from "@mui/material";
import themeDark from "assets/theme-dark";
import theme from "assets/theme";

function Sidenav({ color, brand, brandName, routes, homePage, ...rest }) {
    const [controller, dispatch] = useSoftUIController();
    const { miniSidenav, darkSidenav, layout, darkMode } = controller;
    const location = useLocation();
    const { pathname } = location;
    let collapsezzz = pathname.split("/").slice(1)[1]
    const collapseName = collapsezzz === undefined ? "" : collapsezzz;
    const [selectedMenu, setSelectedMenu] = useState(null);

    const closeSidenav = () => setMiniSidenav(dispatch, true);

    useEffect(() => {
        // A function that sets the mini state of the sidenav.
        function handleMiniSidenav() {
            setMiniSidenav(dispatch, window.innerWidth < 1200);
        }

        /** 
         The event listener that's calling the handleMiniSidenav function when resizing the window.
        */
        window.addEventListener("resize", handleMiniSidenav);

        // Call the handleMiniSidenav function to set the state with the initial value.
        handleMiniSidenav();

        // Remove event listener on cleanup
        return () => window.removeEventListener("resize", handleMiniSidenav);
    }, [dispatch, location]);

    const renderChildren = (collapse) => {

        const xx = collapse.map(({ name, key, route }) => {
            return (
                <SidenavChild
                    color={color}
                    name={name}
                    active={key.includes(pathname)}
                    route={route}
                    key={key}
                />
            )
        })

        return <List component="div" disablePadding>{xx}</List>
    }

    const handleClickMenu = (params) => {
        selectedMenu === params ? setSelectedMenu(null) : setSelectedMenu(params)
    }

    // Render all the routes from the routes.js (All the visible items on the Sidenav)
    const renderRoutes = routes.map(({ type, name, icon, title, noCollapse, key, route, href, collapse }) => {
        let returnValue;

        if (type === "route") {
            returnValue = href ? ( //jika ya buka di tab baru
                <Link
                    href={href}
                    key={key}
                    target="_blank"
                    rel="noreferrer"
                    sx={{ textDecoration: "none" }}
                >
                    <SidenavCollapse
                        color={darkSidenav ? "white" : "dark"}
                        name={name}
                        icon={icon}
                        active={key === collapseName}
                        noCollapse={noCollapse}
                    />
                </Link>
            ) : ( // buka di tab yang sama
                <NavLink to={route} key={key}>
                    <SidenavCollapse
                        color={darkSidenav ? "white" : "dark"}
                        key={key}
                        name={name}
                        icon={icon}
                        active={key.includes(collapseName)}
                        noCollapse={noCollapse}
                    // open={selectedMenu === key}
                    // onClick={(e) => handleClickMenu(key)}
                    // children={collapse && renderChildren(collapse)}
                    >
                    </SidenavCollapse>
                </NavLink>
            );
        } else if (type === 'collapse') {
            returnValue = (
                <SidenavCollapse
                    color={darkSidenav ? "white" : "dark"}
                    key={key}
                    name={name}
                    icon={icon}
                    active={key.includes(collapseName)}
                    noCollapse={noCollapse}
                    open={selectedMenu === key}
                    onClick={(e) => handleClickMenu(key)}
                    children={collapse && renderChildren(collapse)}
                >
                </SidenavCollapse>
            )
        } else if (type === "title") {
            returnValue = (
                <SoftTypography
                    key={key}
                    color={darkSidenav ? "white" : "dark"}
                    display="block"
                    variant="caption"
                    fontWeight="bold"
                    textTransform="uppercase"
                    opacity={0.6}
                    pl={3}
                    mt={2}
                    mb={1}
                    ml={1}
                >
                    {title}
                </SoftTypography>
            );
        } else if (type === "divider") {
            returnValue = <Divider key={key} light={darkSidenav} />;
        }

        return returnValue;
    });

    return (
        <ThemeProvider theme={darkMode ? themeDark : theme}>
            <SidenavRoot {...rest} variant="permanent" ownerState={{ darkSidenav, miniSidenav, layout }}>
                <SoftBox pt={3} pb={1} px={4} textAlign="center">
                    <SoftBox
                        display={{ xs: "block", xl: "none" }}
                        position="absolute"
                        top={0}
                        right={0}
                        p={1.625}
                        onClick={closeSidenav}
                        sx={{ cursor: "pointer" }}
                    >
                        <SoftTypography variant="h6" color="secondary">
                            <Icon sx={{ fontWeight: "bold" }}>close</Icon>
                        </SoftTypography>
                    </SoftBox>
                    <SoftBox component={NavLink} to="/" display="flex" alignItems="center">
                        {brand && <SoftBox component="img" src={brand} alt="Soft UI Logo" width={brandName ? '2rem' : '100%'} />}
                        <SoftBox
                            width={!brandName && "0%"}
                            sx={(theme) => sidenavLogoLabel(theme, { miniSidenav })}
                        >
                            <SoftTypography component="h6" variant="button" fontWeight="bold" color={darkSidenav ? "white" : "dark"}>
                                {brandName}
                            </SoftTypography>
                        </SoftBox>
                    </SoftBox>
                </SoftBox>
                <Divider light={darkSidenav} />
                <List>{renderRoutes}</List>
            </SidenavRoot>
        </ThemeProvider>
    );
}

// Setting default values for the props of Sidenav
Sidenav.defaultProps = {
    color: "dark",
    brand: "",
};

// Typechecking props for the Sidenav
Sidenav.propTypes = {
    color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark", "white"]),
    brand: PropTypes.string,
    brandName: PropTypes.string.isRequired,
    routes: PropTypes.arrayOf(PropTypes.object).isRequired,
    homePage: PropTypes.string.isRequired,
};

export default Sidenav;
