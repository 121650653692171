import { Diversity3, Group, Home, Interests, PersonAdd } from "@mui/icons-material";

const entryRoutes = [
    {
        type: "route",
        name: "Dashboard",
        key: ['pemilih'],
        route: "/entry/pemilih",
        icon: <Home size="12px" color="warning" />,
        noCollapse: true,
    },
    // {
    //     type: "collapse",
    //     name: "Program",
    //     key: ['dtd', 'pt', 'bansos'],
    //     icon: <Interests size="12px" color="info" />,
    //     collapse: [
    //         {
    //             name: "Pendataan DTD",
    //             key: ["/entry/dtd"],
    //             route: "/entry/dtd"
    //         },
    //         {
    //             name: "Pertemuan Terbatas",
    //             key: ["/entry/pt", "/entry/pt/detail"],
    //             route: "/entry/pt"
    //         },
    //     ],
    // },
    {
        type: "route",
        name: "Pendataan DTD",
        key: ['dtd'],
        route: "/entry/dtd",
        icon: <Interests size="12px" color="info" />,
        noCollapse: true,
    },
    // {
    //     type: "route",
    //     name: "Pertemuan Terbatas",
    //     key: ['pt'],
    //     route: "/entry/pt",
    //     icon: <Diversity3 size="12px" color="secondary" />,
    //     noCollapse: true,
    // },
    {
        type: "route",
        name: "Suara Tambahan",
        key: ['suara-tambahan'],
        route: "/entry/suara-tambahan",
        icon: <PersonAdd size="12px" color="error" />,
        noCollapse: true,
    },
    { type: "divider", title: "Account Pages", key: "settings-divider" },
    { type: "title", title: "Settings", key: "settings" },
    {
        type: "route",
        name: "Tim",
        key: ['tim'],
        route: "/entry/tim",
        icon: <Group size="12px" color="success" />,
        noCollapse: true,
    }
    // {
    //     type: "collapse",
    //     name: "Kecamatan",
    //     key: ['kecamatan'],
    //     route: "/entry/kecamatan",
    //     icon: <LocationOn size="12px" />,
    //     noCollapse: true,
    // },
]

export default entryRoutes;