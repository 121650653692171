/* eslint-disable react/jsx-pascal-case */
import { Delete, Download, Edit, FileDownload, Info, Print } from '@mui/icons-material'
import { Box, IconButton, Tooltip } from '@mui/material'
import { MaterialReactTable, MRT_ShowHideColumnsButton, MRT_ToggleDensePaddingButton, useMaterialReactTable, MRT_ToggleFullScreenButton } from 'material-react-table'
import { Link } from 'react-router-dom'
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';

const ServerSideTable = ({
    loading,
    data = [],
    columns,
    rowCount = 10,
    baseUrl,
    pagination,
    setPagination,
    columnFilter,
    setColumnFilter,
    columnVisibility,
    deleteFunc,
    editUrl,
    exportFunc
}) => {

    const exportToExcel = (params) => {
        let exportDataArr = [];
        // data.forEach((dt) => {
        //     // let exportData = Object.assign({}, dt);
        //     // exportData.nama_pd = dt.opd.nama_pd
        //     // exportData.namaDesa = dt.dataDesa === null ? '' : dt.dataDesa.nama_desa
        //     // exportData.namaKec = dt.dataKecamatan === null ? '' : dt.dataKecamatan.nama_kec
        //     // delete exportData.opd
        //     // delete exportData.path
        //     // console.log(dt);
        //     // exportDataArr.push(exportData)
        //     exportDataArr.push(dt)
        // })

        const worksheet = XLSX.utils.json_to_sheet(params);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
        saveAs(blob, `Export.xlsx`);
    };

    const table = useMaterialReactTable({
        data: data,
        columns: columns,
        muiTableHeadCellProps: {
            sx: {
                fontSize: '0.875rem !important',
                color: 'text.main !important'
            }
        },
        muiTableFooterCellProps: {
            sx: {
                fontSize: '0.875rem !important',
                color: 'text.main !important'
            }
        },
        muiTopToolbarProps: {
            sx: { fontSize: '0.875rem' }
        },
        initialState: {
            columnVisibility: columnVisibility,
            showGlobalFilter: false,
            showColumnFilters: true,
        },
        enablePagination: true,
        manualPagination: true,
        muiPaginationProps: {
            rowsPerPageOptions: [10, 20, 50, 100],
            showFirstButton: true,
            showLastButton: true,
        },
        onPaginationChange: setPagination,
        rowCount: rowCount,

        manualFiltering: true, //turn off client-side filtering
        // onGlobalFilterChange={setGlobalFilter}
        onColumnFiltersChange: setColumnFilter,

        enableSorting: false,
        // manualSorting={true}
        // onSortingChange={setSorting}

        enableColumnFilters: true,
        renderToolbarInternalActions: ({ table }) => (
            <>
                {
                    exportFunc &&
                    <Tooltip title="Export">
                        <IconButton
                            onClick={() => {
                                exportFunc()
                            }}
                        >
                            <Download />
                        </IconButton>
                    </Tooltip>
                }
                <MRT_ShowHideColumnsButton table={table} color='text' />
                <MRT_ToggleFullScreenButton table={table} color='text' />
                <MRT_ToggleDensePaddingButton table={table} color='text' />
                {/* <Tooltip title="Print">
                    <IconButton onClick={() => window.print()}>
                        <Print />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Export">
                    <IconButton onClick={() => exportToExcel(data)}>
                        <FileDownload />
                    </IconButton>
                </Tooltip> */}
            </>
        ),
        state: {
            isLoading: loading,
            columnFilter,
            pagination: {
                pageSize: pagination.pageSize,
                pageIndex: pagination.pageIndex
            },
            // globalFilter,
            // sorting,
        },
        enableRowActions: true,
        positionActionsColumn: 'first',
        renderRowActions: ({ row, table }) => (
            <Box >
                <Tooltip title="Detail">
                    <IconButton
                        id="basic-button"
                        color='primary'
                        component={Link}
                        to={baseUrl + '/detail'}
                        state={row.original}
                    >
                        <Info fontSize='small' />
                    </IconButton>
                </Tooltip>
                {
                    deleteFunc &&
                    <Tooltip title="Detail">
                        <IconButton
                            id="basic-button"
                            color='error'
                            onClick={() => deleteFunc(row.original)}
                        >
                            <Delete fontSize='small' />
                        </IconButton>
                    </Tooltip>
                }
                {
                    editUrl &&
                    <IconButton
                        id="basic-button"
                        color='warning'
                        component={Link}
                        to={editUrl}
                        state={row.original}
                    >
                        <Edit fontSize='small' />
                    </IconButton>
                }
            </Box>
        ),
        displayColumnDefOptions: {
            'mrt-row-actions': {
                header: 'Aksi', //change header text
                size: 100, //make actions column wider
                alignment: 'center'
            },

        },
        muiFilterTextFieldProps: {
            sx: { width: '100%', paddingLeft: '0rem !important' },
            variant: 'outlined',
        },
        muiTableBodyCellProps: {
            sx: {
                fontWeight: 'normal',
                fontSize: '0.875rem !important',
            },
        }
    }, [columns])

    return (
        <MaterialReactTable table={table} />
        // <MaterialReactTable
        //     columns={columns}
        //     data={data}
        //     muiTableHeadCellProps={{
        //         sx: {
        //             fontSize: '0.875rem !important',
        //             color: 'text.main !important'
        //         }
        //     }}
        //     muiTableFooterCellProps={{
        //         sx: {
        //             fontSize: '0.875rem !important',
        //             color: 'text.main !important'
        //         }
        //     }}
        //     muiTopToolbarProps={{
        //         sx: { fontSize: '0.875rem' }
        //     }}
        //     initialState={{
        //         columnVisibility: columnVisibility,
        //         showGlobalFilter: false
        //     }}
        //     enablePagination={true}
        //     manualPagination={true}
        //     muiPaginationProps={{
        //         rowsPerPageOptions: [10, 20, 50, 100]
        //     }}
        //     onPaginationChange={setPagination}
        //     rowCount={rowCount}

        //     manualFiltering={true} //turn off client-side filtering
        //     // onGlobalFilterChange={setGlobalFilter}
        //     onColumnFiltersChange={setColumnFilter}

        //     enableSorting={false}
        //     // manualSorting={true}
        //     // onSortingChange={setSorting}

        //     // enableColumnFilters={false}
        //     renderToolbarInternalActions={({ table }) => (
        //         <>
        //             {/* <MRT_ToggleGlobalFilterButton table={table} color='text' /> */}
        //             <MRT_ShowHideColumnsButton table={table} color='text' />
        //             <MRT_FullScreenToggleButton table={table} color='text' />
        //             <MRT_ToggleDensePaddingButton table={table} color='text' />
        //             <Tooltip title="Print">
        //                 <IconButton onClick={() => window.print()}>
        //                     <Print />
        //                 </IconButton>
        //             </Tooltip>
        //             <Tooltip title="Export">
        //                 <IconButton onClick={() => exportToExcel()}>
        //                     <FileDownload />
        //                 </IconButton>
        //             </Tooltip>
        //         </>
        //     )}
        //     state={{
        //         isLoading: loading,
        //         showColumnFilters: true,
        //         pagination: {
        //             pageSize: pagination.pageSize,
        //             pageIndex: pagination.pageIndex
        //         },
        //         // globalFilter,
        //         // sorting,
        //         columnFilter
        //     }}
        //     enableRowActions={true}
        //     positionActionsColumn='first'
        //     renderRowActions={({ row, table }) => (
        //         <Tooltip title="Detail">
        //             <IconButton
        //                 id="basic-button"
        //                 color='primary'
        //                 component={Link}
        //                 to={baseUrl + '/detail'}
        //                 state={row.original.id}
        //             >
        //                 <ArrowCircleRight fontSize='small' />
        //             </IconButton>
        //         </Tooltip>
        //     )}
        //     displayColumnDefOptions={{
        //         'mrt-row-actions': {
        //             header: 'Aksi', //change header text
        //             size: 50, //make actions column wider
        //             alignment: 'left'
        //         },

        //     }}
        //     muiFilterTextFieldProps={{
        //         sx: { width: '100%', paddingLeft: '0rem !important' },
        //         variant: 'outlined',
        //     }}
        // />
    )
}

export default ServerSideTable