import { ArrowBack, ExpandMore, Save } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { Accordion, AccordionDetails, AccordionSummary, Autocomplete, Card, CardContent, CardHeader, Divider, Grid, IconButton, List, ListItem, ListItemText, MenuItem, Select, TextField, Tooltip, Typography } from '@mui/material'
import axios from 'axios'
import SoftBox from 'components/SoftBox'
import SoftInput from 'components/SoftInput'
import SoftTypography from 'components/SoftTypography'
import { CustomDescrypt } from 'global/func'
import { postSubmit } from 'global/postFunc'
import { postDataWithResponse } from 'global/postFunc'
import { serverUrl } from 'global/variable'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import userStore from 'store/userStore'

const TambahKorcam = () => {
    const { user, addReq } = userStore(state => ({ user: state.user, addReq: state.addReq }));
    const decryptedUser = (user === null || user === undefined || user === '') ? "" : JSON.parse(CustomDescrypt(user));

    const navigate = useNavigate();
    const [listDpt, setListDpts] = useState([])
    const [kecamatans, setKecamatans] = useState([])

    const [loading, setLoading] = useState(false)

    const [desas, setDesas] = useState([])
    const [desa, setDesa] = useState('')
    const [dpt, setDpt] = useState({})

    const [formData, setFormData] = useState({
        nik: '',
        nama: '',
        no_hp: '',
        id_kec: '',
        nama_kec: '',
        id_kel: '',
        nama_kel: '',
        lat: '',
        longi: ''
    })

    useEffect(() => {
        console.log(formData);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formData])

    const reqDpt = (id_kel) => {
        addReq()
        setLoading(true)
        postDataWithResponse(
            '/dpt',
            {
                id_kel: id_kel,
                status: ''
            },
            decryptedUser,
            (res) => {
                setLoading(false)
                setListDpts(res)
            },
            (error) => {
                setLoading(false)
                console.log(error)
            }
        )
    }

    const submit = (event) => {
        event.preventDefault();
        const frm = {
            nama: formData.nama,
            nik: formData.nik,
            no_hp: formData.no_hp,
            id_kec: formData.id_kec,
            nama_kec: formData.nama_kec,
            id_kel: desa.id_desa,
            nama_kel: desa.nama_desa,
            status: 'Korcam',
            lat: formData.lat,
            longi: formData.longi,
        }
        setLoading(true)
        addReq()
        postSubmit(
            '/entry/korcam/insert',
            frm,
            decryptedUser,
            afterSubmit,
            (error) => {
                setLoading(false)
                console.log(error);
            }
        )
    }

    const afterSubmit = () => {
        setLoading(false)
        navigate(-1)
    }

    const handleChangeDesa = (event) => {
        if (event.target.value !== '') {
            let resultObject = desas.find(x => x.id_desa === event.target.value)
            reqDpt(event.target.value)
            setDesa(resultObject)
            console.log(resultObject);
        }
    };

    useEffect(() => {
        getKec()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getKec = async () => {
        addReq()
        const kec = await axios.get(serverUrl + '/kecamatan');

        setKecamatans(kec.data)
    }

    const handleChangeKec = (event) => {
        // setDesas([])
        if (event.target.value === '') {
            setFormData({ ...formData, id_kec: '', nama_kec: '' })
        }
        else {
            let resultObject = kecamatans.find(x => x.id_kec === event.target.value)
            setFormData({ ...formData, id_kec: event.target.value, nama_kec: resultObject.nama_kec })
            getDesa(event.target.value)
            // event.target.value !== '' && getDesa(event.target.value)
        }
    };

    const getDesa = async (params) => {
        addReq()
        const data = await axios.get(serverUrl + '/desa/' + params);
        setDesas(data.data)
        // console.log(data);
    }

    return (
        <SoftBox mb={3}>
            <Grid container spacing={3} justifyContent='center' py={5}>
                <Grid item xs={12} sm={12} md={9}>
                    <Card >
                        <CardHeader
                            title={
                                <SoftTypography variant='h6'>Kembali</SoftTypography>
                            }
                            avatar={
                                <Tooltip title="Kembali">
                                    <IconButton variant="outlined" onClick={() => navigate(-1)}><ArrowBack /></IconButton>
                                </Tooltip>
                            }
                        />
                        <CardContent component='form' onSubmit={submit}>
                            <Grid container justifyContent='center' direction='column'>
                                <Grid item xs={12} sm={12} lg={6} mb={2}>
                                    <SoftBox mb={1} ml={0.5}>
                                        <SoftTypography component="label" variant="caption" fontWeight="bold">
                                            Pilih Kecamatan
                                        </SoftTypography>
                                    </SoftBox>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        defaultValue=''
                                        value={formData.id_kec || ""}
                                        label="Kecamatan"
                                        onChange={handleChangeKec}
                                        required
                                    >
                                        <MenuItem value=''><em>Pilih Kecamatan</em></MenuItem>
                                        {
                                            kecamatans.map((kec) => (
                                                <MenuItem key={kec.id_kec} value={kec.id_kec}>{kec.nama_kec}</MenuItem>
                                            ))
                                        }
                                    </Select>
                                </Grid>
                                <Grid item xs={12} sm={12} lg={6} mb={2}>
                                    <SoftBox mb={1} ml={0.5}>
                                        <SoftTypography component="label" variant="caption" fontWeight="bold">
                                            Alamat
                                        </SoftTypography>
                                    </SoftBox>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        defaultValue=''
                                        value={desa.id_desa || ''}
                                        label="Desa"
                                        onChange={handleChangeDesa}
                                        required
                                    >
                                        <MenuItem value=''><em>Pilih Desa</em></MenuItem>
                                        {
                                            desas.map((des) => (
                                                <MenuItem key={des.id_desa} value={des.id_desa}>{des.nama_desa}</MenuItem>
                                            ))
                                        }
                                    </Select>
                                </Grid>
                                <Grid item xs={12} sm={12} lg={6}>
                                    <SoftBox mb={2} justifyContent="center">
                                        <SoftBox mb={1} ml={0.5}>
                                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                Nama
                                            </SoftTypography>
                                        </SoftBox>
                                        <Autocomplete
                                            // disablePortal
                                            freeSolo
                                            id="combo-box-demo"
                                            options={listDpt}
                                            fullWidth
                                            getOptionLabel={(option) => option.nik + ' ' + option.nama}
                                            // getOptionLabel={(option) => option.nama}
                                            onChange={(event, newValue) => {
                                                newValue !== null && setFormData({ ...formData, nik: newValue.nik, nama: newValue.nama })
                                                newValue !== null && setDpt(newValue)
                                            }}
                                            onInputChange={(event, newInputValue) => {
                                                newInputValue === "" && setFormData({ ...formData, nik: '', nama: '' })
                                                newInputValue === "" && setDpt({})
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                />
                                            )}
                                        />
                                    </SoftBox>
                                </Grid>
                                <Grid item xs={12} sm={12} lg={6}>
                                    <SoftBox mb={2} justifyContent="center">
                                        <SoftBox mb={1} ml={0.5}>
                                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                No. HP
                                            </SoftTypography>
                                        </SoftBox>
                                        <SoftInput
                                            type="text"
                                            defaultValue={formData.no_hp}
                                            onChange={(event) => setFormData({ ...formData, no_hp: event.target.value })}
                                            autoComplete='off'
                                            required
                                            inputProps={{ maxLength: 12 }}
                                        />
                                    </SoftBox>
                                </Grid>
                                <Grid item xs={12} sm={12} lg={6}>
                                    <SoftBox mb={2} justifyContent="center">
                                        <SoftBox mb={1} ml={0.5}>
                                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                Latitude
                                            </SoftTypography>
                                        </SoftBox>
                                        <SoftInput
                                            type="text"
                                            defaultValue={formData.lat}
                                            onChange={(event) => setFormData({ ...formData, lat: event.target.value })}
                                            autoComplete='off'
                                            required
                                        />
                                    </SoftBox>
                                </Grid>
                                <Grid item xs={12} sm={12} lg={6}>
                                    <SoftBox mb={2} justifyContent="center">
                                        <SoftBox mb={1} ml={0.5}>
                                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                Longitude
                                            </SoftTypography>
                                        </SoftBox>
                                        <SoftInput
                                            type="text"
                                            defaultValue={formData.longi}
                                            onChange={(event) => setFormData({ ...formData, longi: event.target.value })}
                                            autoComplete='off'
                                            required
                                        />
                                    </SoftBox>
                                </Grid>
                                {
                                    formData.nik !== '' && (
                                        <Grid container spacing={3} pt={3} justifyContent="center">
                                            <Grid item xs={12} sm={12} md={10} lg={8}>
                                                <Accordion>
                                                    <AccordionSummary
                                                        expandIcon={<ExpandMore />}
                                                        aria-controls="panel1-content"
                                                        id="panel1-header"
                                                    >
                                                        <Typography variant='button'>
                                                            Detail DPT
                                                        </Typography>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        <List sx={{ bgcolor: 'background.paper' }}>
                                                            <ListItem>
                                                                <ListItemText primary={
                                                                    <Typography
                                                                        variant="body2"
                                                                        color="text.primary"
                                                                    >
                                                                        NIK
                                                                    </Typography>
                                                                }
                                                                    secondary={
                                                                        <Typography
                                                                            variant="body2"
                                                                            color="text.primary"
                                                                        >
                                                                            {dpt.nik ? dpt.nik : '-'}
                                                                        </Typography>
                                                                    } />
                                                            </ListItem>
                                                            <Divider />
                                                            <ListItem>
                                                                <ListItemText primary={
                                                                    <Typography
                                                                        variant="body2"
                                                                        color="text.primary"
                                                                    >
                                                                        No. KK
                                                                    </Typography>
                                                                }
                                                                    secondary={
                                                                        <Typography
                                                                            variant="body2"
                                                                            color="text.primary"
                                                                        >
                                                                            {dpt.no_kk ? dpt.no_kk : '-'}
                                                                        </Typography>
                                                                    } />
                                                            </ListItem>
                                                            <Divider />
                                                            <ListItem>
                                                                <ListItemText primary={
                                                                    <Typography
                                                                        variant="body2"
                                                                        color="text.primary"
                                                                    >
                                                                        Nama
                                                                    </Typography>
                                                                }
                                                                    secondary={
                                                                        <Typography
                                                                            variant="body2"
                                                                            color="text.primary"
                                                                        >
                                                                            {dpt.nama ? dpt.nama : '-'}
                                                                        </Typography>
                                                                    } />
                                                            </ListItem>
                                                            <Divider />
                                                            <ListItem>
                                                                <ListItemText
                                                                    primary={
                                                                        <Typography
                                                                            variant="body2"
                                                                            color="text.primary"
                                                                        >
                                                                            Tempat/Tgl Lahir
                                                                        </Typography>
                                                                    }
                                                                    secondary={
                                                                        <Typography
                                                                            variant="body2"
                                                                            color="text.primary"
                                                                        >
                                                                            {dpt.tempat_lahir ? dpt.tempat_lahir + ', ' + dpt.tgl_lahir : '-'}
                                                                        </Typography>
                                                                    }
                                                                />
                                                            </ListItem>
                                                            <Divider />
                                                            <ListItem>
                                                                <ListItemText
                                                                    primary={
                                                                        <Typography
                                                                            variant="body2"
                                                                            color="text.primary"
                                                                        >
                                                                            Status Kawin
                                                                        </Typography>
                                                                    }
                                                                    secondary={
                                                                        <Typography
                                                                            variant="body2"
                                                                            color="text.primary"
                                                                        >
                                                                            {
                                                                                dpt.stts_kawin
                                                                            }
                                                                        </Typography>
                                                                    }
                                                                />
                                                            </ListItem>
                                                            <Divider />
                                                            <ListItem>
                                                                <ListItemText
                                                                    primary={
                                                                        <Typography
                                                                            variant="body2"
                                                                            color="text.primary"
                                                                        >
                                                                            Jenis Kelamin
                                                                        </Typography>
                                                                    }
                                                                    secondary={
                                                                        <Typography
                                                                            variant="body2"
                                                                            color="text.primary"
                                                                        >
                                                                            {dpt.jenkel === 'L' ? 'Laki-laki' : 'Perempuan'}
                                                                        </Typography>
                                                                    }
                                                                />
                                                            </ListItem>
                                                            <Divider />
                                                            <ListItem>
                                                                <ListItemText
                                                                    primary={
                                                                        <Typography
                                                                            variant="body2"
                                                                            color="text.primary"
                                                                        >
                                                                            Alamat
                                                                        </Typography>
                                                                    }
                                                                    secondary={
                                                                        <Typography
                                                                            variant="body2"
                                                                            color="text.primary"
                                                                        >
                                                                            {dpt.alamat ? `${dpt.alamat} RT. ${dpt.rt} RW. ${dpt.rw}, Kel. ${dpt.nama_kel}, Kec. ${dpt.nama_kec}, Kota. ${dpt.nama_kab}` : '-'}
                                                                        </Typography>
                                                                    } />
                                                            </ListItem>
                                                            <Divider />
                                                            <ListItem>
                                                                <ListItemText
                                                                    primary={
                                                                        <Typography
                                                                            variant="body2"
                                                                            color="text.primary"
                                                                        >
                                                                            RT / RW
                                                                        </Typography>
                                                                    }
                                                                    secondary={
                                                                        <Typography
                                                                            variant="body2"
                                                                            color="text.primary"
                                                                        >
                                                                            RT. {dpt.rt}, RW. {dpt.rw}
                                                                        </Typography>
                                                                    } />
                                                            </ListItem>
                                                            <Divider />
                                                            <ListItem>
                                                                <ListItemText primary={
                                                                    <Typography
                                                                        variant="body2"
                                                                        color="text.primary"
                                                                    >
                                                                        TPS
                                                                    </Typography>
                                                                }
                                                                    secondary={
                                                                        <Typography
                                                                            variant="body2"
                                                                            color="text.primary"
                                                                        >
                                                                            {dpt.nama_tps}
                                                                        </Typography>
                                                                    } />
                                                            </ListItem>
                                                            <Divider />
                                                        </List>
                                                    </AccordionDetails>
                                                </Accordion>
                                            </Grid>
                                        </Grid>
                                    )
                                }
                                <Grid item xs={12} sm={12} lg={6}>
                                    <Grid container justifyContent='flex-end'>
                                        <Grid item>
                                            <SoftBox mb={1} ml={0.5}>
                                                <LoadingButton
                                                    loading={loading}
                                                    loadingPosition="start"
                                                    startIcon={<Save />}
                                                    variant="contained"
                                                    type='submit'
                                                >
                                                    Save
                                                </LoadingButton>
                                            </SoftBox>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </SoftBox>
    )
}

export default TambahKorcam