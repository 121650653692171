import { ArrowBack, Save, Visibility, VisibilityOff } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Card, CardContent, CardHeader, CircularProgress, Grid, IconButton, InputAdornment, Modal, Tooltip } from '@mui/material'
import SoftBox from 'components/SoftBox';
import SoftInput from 'components/SoftInput';
import SoftTypography from 'components/SoftTypography';
import { CustomDescrypt } from 'global/func';
import { postSubmit } from 'global/postFunc';
import React, { useState } from 'react'
import userStore from 'store/userStore';

const Tambah = ({ text, deleting, setDeleting, ...props }) => {
    const { user, addReq } = userStore(state => ({ user: state.user, addReq: state.addReq }));
    const decryptedUser = (user === null || user === undefined || user === '') ? "" : JSON.parse(CustomDescrypt(user));

    const [loading, setLoading] = useState(false)
    const [formData, setFormData] = useState({
        username: '',
        password: '',
        nama: ''
    })

    const [showPassword, setShowPassword] = useState(false);
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const submit = (event) => {
        event.preventDefault();
        setLoading(true)
        addReq()
        postSubmit(
            '/admin/entry/insert',
            formData,
            decryptedUser,
            afterSubmit,
            (error) => {
                setLoading(false)
                console.log(error);
            }
        )
    }

    const afterSubmit = () => {
        setLoading(false)
        setDeleting(!deleting)
        setFormData({})
        handleClose()
    }

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    return (
        <>
            <Button onClick={handleOpen} {...props}>{text}</Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{
                    overflow: 'scroll',
                }}
            >
                <Grid container spacing={3} justifyContent='center' py={5}>
                    <Grid item xs={12} sm={12} md={6} lg={4}>
                        <Card >
                            <CardHeader
                                title={
                                    <SoftTypography variant='h6'>Kembali</SoftTypography>
                                }
                                avatar={
                                    <Tooltip title="Kembali">
                                        <IconButton variant="outlined" onClick={handleClose}><ArrowBack /></IconButton>
                                    </Tooltip>
                                }
                            />
                            {
                                loading ?
                                    (
                                        <Grid container spacing={3} py={5} justifyContent="center">
                                            <Grid item>
                                                <Box sx={{ display: 'flex' }}>
                                                    <CircularProgress />
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    )
                                    :
                                    (
                                        <CardContent component='form' onSubmit={submit}>
                                            <SoftBox>
                                                <SoftBox mb={1} ml={0.5}>
                                                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                        Nama
                                                    </SoftTypography>
                                                </SoftBox>
                                                <SoftInput
                                                    type="text"
                                                    defaultValue={formData.nama}
                                                    onChange={(event) => setFormData({ ...formData, nama: event.target.value })}
                                                    autoComplete='off'
                                                />
                                            </SoftBox>
                                            <SoftBox>
                                                <SoftBox mb={1} ml={0.5}>
                                                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                        Username
                                                    </SoftTypography>
                                                </SoftBox>
                                                <SoftInput
                                                    type="text"
                                                    defaultValue={formData.username}
                                                    onChange={(event) => setFormData({ ...formData, username: event.target.value })}
                                                    autoComplete='off'
                                                />
                                            </SoftBox>
                                            <SoftBox>
                                                <SoftBox mb={1} ml={0.5}>
                                                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                        Password
                                                    </SoftTypography>
                                                </SoftBox>
                                                <SoftInput
                                                    type={showPassword ? 'text' : 'password'}
                                                    placeholder="Password"
                                                    defaultValue={formData.password}
                                                    onChange={(e) => setFormData({ ...formData, password: e.target.value })}
                                                    autoComplete='off'
                                                    endAdornment={
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                aria-label="toggle password visibility"
                                                                onClick={handleClickShowPassword}
                                                                onMouseDown={handleMouseDownPassword}
                                                            >
                                                                {showPassword ? <VisibilityOff fontSize="small" /> : <Visibility fontSize="small" />}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    }
                                                />
                                            </SoftBox>
                                            <SoftBox mb={1} ml={0.5} mt={3} display="flex" flexDirection="row" justifyContent="end">
                                                <LoadingButton
                                                    loading={loading}
                                                    loadingPosition="start"
                                                    startIcon={<Save />}
                                                    variant="contained"
                                                    type='submit'
                                                >
                                                    Save
                                                </LoadingButton>
                                            </SoftBox>
                                        </CardContent>
                                    )
                            }

                        </Card>
                    </Grid>
                </Grid >
            </Modal >
        </>
    )
}

export default Tambah