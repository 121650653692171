import { ArrowBack, Save } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box, Card, CardContent, CardHeader, CircularProgress, Grid, IconButton, Tooltip } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import Headerx from 'components/Header/Headerx'
import SoftBox from 'components/SoftBox';
import SoftInput from 'components/SoftInput';
import SoftTypography from 'components/SoftTypography';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { CustomDescrypt } from 'global/func';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import userStore from 'store/userStore';
import SelectKec from 'myComponent/SelectKec';
import { postSubmit } from 'global/postFunc';

const Tambah = () => {
    const navigate = useNavigate();
    const encryptUser = localStorage.getItem('AuthPersist');
    const encryptUserJson = JSON.parse(encryptUser);
    const decryptedUser = encryptUserJson.state.user === null ? "" : JSON.parse(CustomDescrypt(encryptUserJson.state.user));
    const logOut = userStore((state) => state.logOut)

    const [tanggal, setTanggal] = useState(dayjs(new Date()));
    const [formData, setFormData] = useState({
        judul: '',
        id_kec: '',
        nama_kec: '',
        tgl: '',
    })

    const [loading, setLoading] = useState(false)

    const submit = (event) => {
        event.preventDefault();
        postSubmit(
            '/entry/pt/insert',
            formData,
            decryptedUser,
            setLoading,
            () => {navigate(-1)},
            logOut
        );
    }

    useEffect(() => {
        console.log(formData);
    }, [formData])

    useEffect(() => {
        setFormData({ ...formData, tgl: tanggal.format('YYYY-MM-DD') })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tanggal])

    return (
        <>
            <Headerx title='Tambah Data' />
            <SoftBox mb={3}>
                <Grid container spacing={3} justifyContent='center' py={5}>
                    <Grid item xs={12} sm={12} md={9}>
                        <Card >
                            <CardHeader
                                title={
                                    <SoftTypography variant='h6'>Kembali</SoftTypography>
                                }
                                avatar={
                                    <Tooltip title="Kembali">
                                        <IconButton variant="outlined" onClick={() => navigate(-1)}><ArrowBack /></IconButton>
                                    </Tooltip>
                                }
                            />
                            {
                                loading ?
                                    (
                                        <Grid container spacing={3} py={5} justifyContent="center">
                                            <Grid item>
                                                <Box sx={{ display: 'flex' }}>
                                                    <CircularProgress />
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    )
                                    :
                                    (
                                        <CardContent component='form' onSubmit={submit}>
                                            <Grid container justifyContent='center' direction='column'>
                                                <Grid item xs={12} sm={12} lg={6}>
                                                    <SoftBox mb={2} justifyContent="center">
                                                        <SoftBox mb={1} ml={0.5}>
                                                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                                Judul Pertemuan
                                                            </SoftTypography>
                                                        </SoftBox>
                                                        <SoftInput
                                                            type="text"
                                                            defaultValue={formData.judul}
                                                            onChange={(event) => setFormData({ ...formData, judul: event.target.value })}
                                                            autoComplete='off'
                                                            multiline
                                                            rows={4}
                                                        />
                                                    </SoftBox>
                                                </Grid>
                                                <Grid item xs={12} sm={12} lg={6}>
                                                    <SoftBox mb={2} justifyContent="center">
                                                        <SoftBox mb={1} ml={0.5}>
                                                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                                Tanggal Pertemuan
                                                            </SoftTypography>
                                                        </SoftBox>
                                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                            <DatePicker variant='outlined' format='YYYY-MM-DD' value={tanggal} onChange={(newValue) => setTanggal(newValue)} sx={{ width: '100% !important' }} />
                                                        </LocalizationProvider>
                                                    </SoftBox>
                                                </Grid>
                                                <Grid item xs={12} sm={12} lg={6}>
                                                    <SelectKec formData={formData} setFormData={setFormData} disabled={false} />
                                                </Grid>
                                                {/* <Grid item xs={12} sm={12} lg={6}>
                                                    <SelectDesa formData={formData} setFormData={setFormData} idKec={formData.id_kec} disabled={false} />
                                                </Grid> */}
                                                <Grid item xs={12} sm={12} lg={6}>
                                                    <Grid container justifyContent='flex-end'>
                                                        <Grid item mt={3}>
                                                            <SoftBox mb={1} ml={0.5}>
                                                                <LoadingButton
                                                                    loading={loading}
                                                                    loadingPosition="start"
                                                                    startIcon={<Save />}
                                                                    variant="contained"
                                                                    type='submit'
                                                                >
                                                                    Save
                                                                </LoadingButton>
                                                            </SoftBox>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    )
                            }

                        </Card>
                    </Grid>
                </Grid>
            </SoftBox>
        </>
    )
}

export default Tambah