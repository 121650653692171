import { HighchartsReact } from 'highcharts-react-official'
import Highcharts from 'highcharts'

const StackedCollumn = ({ title = '', categories = [], series = [] }) => {
    // console.log(series);
    

    const options = {
        chart: {
            type: 'column'
        },
        title: {
            text: title,
        },
        xAxis: {
            categories: categories,
        },
        yAxis: {
            // min: 0,
            title: {
                text: 'Perolehan'
            },
            stackLabels: {
                enabled: true
            },
            gridLineWidth: 0,
        },
        tooltip: {
            shared: true,
            headerFormat: '<b>{point.x}</b><br/>',
            pointFormat: '{series.name}: {point.y}<br/>'
        },
        plotOptions: {
            column: {
                stacking: 'normal',
                dataLabels: {
                    enabled: true
                }
            }
        },
        series: series
    }

    return (
        <HighchartsReact
            highcharts={Highcharts}
            options={options}
        />
    )
}

export default StackedCollumn