import { AddCircleOutline, Delete, Interests } from '@mui/icons-material'
import { Avatar, Button, Card, CardContent, Divider, Grid, IconButton, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, Typography } from '@mui/material'
import MiniStatisticsCard from 'components/Cards/StatisticsCards/MiniStatisticsCard'
import Headerx from 'components/Header/Headerx'
import SoftBox from 'components/SoftBox'
import { CustomDescrypt } from 'global/func'
import LoadingList from 'myComponent/LoadingList'
import React, { useEffect, useState } from 'react'
import { Link, Route, Routes } from 'react-router-dom'
import userStore from 'store/userStore'
import Swal from 'sweetalert2'
import Tambah from './Tambah'
import Detail from './Detail'
import TambahPenerima from './TambahPenerima'
import { getDataWithResponse } from 'global/getFunc'
import { postDelete } from 'global/postFunc'

const Bansos = () => {
    return (
        <Routes>
            <Route exact path='' element={<Data />} />
            <Route exact path='detail' element={<Detail />} />
            <Route exact path='tambah' element={<Tambah />} />
            <Route exact path='tambah-penerima' element={<TambahPenerima />} />
            {/* <Route exact path='edit' element={<Edit />} /> */}
        </Routes>
    )
}

const Data = () => {
    const encryptUser = localStorage.getItem('AuthPersist');
    const encryptUserJson = JSON.parse(encryptUser);
    const decryptedUser = encryptUserJson.state.user === null ? "" : JSON.parse(CustomDescrypt(encryptUserJson.state.user));
    const logOut = userStore((state) => state.logOut)

    const [loading, setLoading] = useState(true)
    const [tableData, setTableData] = useState([]);
    const [deleting, setDeleting] = useState(false);

    useEffect(() => {
        requestData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deleting])

    const requestData = () => {
        setLoading(true)
        getDataWithResponse(
            '/entry/bansos',
            decryptedUser,
            setLoading,
            setTableData,
            logOut
        )
    }

    const deleteData = (params) => {
        console.log(params);
        Swal.fire({
            title: 'Hapus Bansos?',
            icon: 'warning',
            text: "Data Penerima akan Otomatis Terhapus",
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ya, hapus!',
            showLoaderOnConfirm: true
        }).then((result) => {
            if (result.isConfirmed) {
                postDelete(
                    '/entry/bansos/delete',
                    {
                        _method: 'DELETE',
                        id: params
                    },
                    decryptedUser,
                    setLoading,
                    () => { setDeleting(!deleting) },
                    logOut
                )
            }
        })
    }

    return (
        <>
            <Headerx title='Pertemuan Terbatas' />
            <SoftBox my={3}>
                <Grid container spacing={3} mb={3} justifyContent='center'>
                    <Grid item xs={12} lg={6}>
                        <MiniStatisticsCard
                            title={{ text: "Jumlah Pertemuan" }}
                            count={tableData.length}
                            // percentage={{ color: "success", text: "+55%" }}
                            icon={{ color: "info", component: "paid" }}
                        />
                    </Grid>
                </Grid>

                <Grid container spacing={3} justifyContent='center'>
                    <Grid item xs={12} lg={6}>
                        <Card >
                            <Grid container justifyContent="space-between" p={2}>
                                <Grid item xs="auto">
                                    <Typography>
                                        Data Bansos
                                    </Typography>
                                </Grid>

                                <Grid item>
                                    <Button
                                        variant="contained"
                                        endIcon={<AddCircleOutline color='white' />}
                                        // onClick={() => handleOpenDialog2()}
                                        to="/entry/bansos/tambah"
                                        component={Link}
                                        disabled={tableData.length >= 10}
                                    >
                                        Tambah
                                    </Button>
                                </Grid>
                            </Grid>
                            {
                                loading ?
                                    (
                                        <>
                                            <LoadingList />
                                            <Divider />
                                            <LoadingList />
                                            <Divider />
                                            <LoadingList />
                                            <Divider />
                                            <LoadingList />
                                            <Divider />
                                            <LoadingList />
                                            <Divider />
                                            <LoadingList />
                                            <Divider />
                                            <LoadingList />
                                            <Divider />
                                            <LoadingList />
                                            <Divider />
                                            <LoadingList />
                                            <Divider />
                                            <LoadingList />
                                            <Divider />
                                        </>
                                    )
                                    :
                                    tableData.length > 0 ?
                                        (
                                            <Grid container justifyContent="center" p={2}>
                                                <Grid item xl={12}>
                                                    <List sx={{ bgcolor: 'background.paper' }}>
                                                        {
                                                            tableData.map((data) => (
                                                                <React.Fragment key={data.id_bansos}>
                                                                    <ListItem
                                                                        alignItems="flex-start"
                                                                        secondaryAction={
                                                                            <IconButton key={1} edge="end" aria-label="delete" onClick={() => deleteData(data.id_bansos)}>
                                                                                <Delete color='error' />
                                                                            </IconButton>

                                                                        }
                                                                    >
                                                                        <ListItemButton
                                                                            component={Link} to="/entry/bansos/detail" state={data}
                                                                        >
                                                                            <ListItemAvatar>
                                                                                <Avatar>
                                                                                    <Interests />
                                                                                </Avatar>
                                                                            </ListItemAvatar>
                                                                            <ListItemText
                                                                                primary={
                                                                                    <Typography
                                                                                        variant="body1"
                                                                                        color="text.primary"
                                                                                    >
                                                                                        {data.judul}
                                                                                    </Typography>
                                                                                }
                                                                                secondary={
                                                                                    <React.Fragment>
                                                                                        <Typography
                                                                                            sx={{ display: 'inline' }}
                                                                                            component="span"
                                                                                            variant="body2"
                                                                                            color="text.primary"
                                                                                        >
                                                                                            {data.jenis}
                                                                                        </Typography>
                                                                                        , {data.tgl} Kec. {data.nama_kec}, Kel. {data.nama_kel}
                                                                                    </React.Fragment>

                                                                                }
                                                                            />
                                                                        </ListItemButton>
                                                                    </ListItem>
                                                                    <Divider />
                                                                </React.Fragment>
                                                            ))
                                                        }
                                                    </List>
                                                </Grid>
                                            </Grid>
                                        )
                                        :
                                        <CardContent>
                                            <Typography variant="body2" color="text.secondary">
                                                Tidak ada data
                                            </Typography>
                                        </CardContent>

                            }
                        </Card>
                    </Grid>
                </Grid>
            </SoftBox>
        </>
    )
}

export default Bansos