import { useEffect, useRef } from "react";
import Map from '@arcgis/core/Map'
import MapView from '@arcgis/core/views/MapView'
import Graphic from '@arcgis/core/Graphic'
import GraphicsLayer from "@arcgis/core/layers/GraphicsLayer";
import PopupTemplate from "@arcgis/core/PopupTemplate";
import SoftBox from "components/SoftBox";

const generateMarkerSymbol = (params) => {
    if (params.status === 'Korcam') {
        return {
            type: "simple-marker",
            color: '#1976d2',  // Orange
            outline: {
                color: [255, 255, 255], // White
                width: 0.2
            },
            size: '9px'
        }
    }
    else if (params.status === 'Relawan') {
        return {
            type: "simple-marker",
            color: '#2e7d32',  // Orange
            outline: {
                color: [255, 255, 255], // White
                width: 0.2
            },
            size: '9px'
        }
    }
    else if (params.status === 'Vote Getter') {
        return {
            type: "simple-marker",
            color: '#ed6c02',  // Orange
            outline: {
                color: [255, 255, 255], // White
                width: 0.2
            },
            size: '9px'
        }
    }
}

const generatePopupTemplate = (params) => {
    let content = [];
    if (params.status === 'Korcam') {
        content =
            "<b>Status:</b> {status} {nama_kec}<br>" +
            "<b>Alamat:</b> Kecamatan {nama_kec} Kelurahan {nama_kel}<br>" +
            "<b>No. HP:</b> {no_hp}<br>" +
            "<b>Latitude:</b> {lat}<br>" +
            "<b>Longitude:</b> {longi}";
    }
    else if (params.status === 'Relawan') {
        content =
            "<b>Status:</b> {status} {nama_kel}<br>" +
            "<b>Alamat:</b> Kecamatan {nama_kec} Kelurahan {nama_kel}<br>" +
            "<b>No. HP:</b> {no_hp}<br>" +
            "<b>Latitude:</b> {lat}<br>" +
            "<b>Longitude:</b> {longi}";
    }
    else if (params.status === 'Vote Getter') {
        content =
            "<b>Status:</b> {status} {nama_kel}<br>" +
            "<b>Alamat:</b> Kecamatan {nama_kec} Kelurahan {nama_kel}<br>" +
            "<b>No. HP:</b> {no_hp}<br>" +
            "<b>Latitude:</b> {lat}<br>" +
            "<b>Longitude:</b> {longi}";
    }
    return new PopupTemplate({
        title: "{nama}",
        content: content,
    })
}

const generateGraphic = (params) => {
    let output;
    console.log(parseFloat(params.lat));
    output = new Graphic({
        geometry: {
            type: "point",
            latitude: parseFloat(params.lat),
            longitude: parseFloat(params.longi)
        },
        symbol: generateMarkerSymbol(params),
        attributes: params,
        // popupTemplate: template
        popupTemplate: generatePopupTemplate(params)
    });
    return output;
}

const MapMultiple = ({ data, basemap = 'streets' }) => {
    const mapRef = useRef()
    // console.log(data);

    useEffect(() => {
        const map = new Map({
            basemap: basemap,
            showAttribution: false
        });

        // eslint-disable-next-line no-unused-vars
        const view = new MapView({
            container: mapRef.current,
            map: map,
            zoom: 11,
            showAttribution: false,
            center: [122.52416679312992, -3.9839974928231157], // longitude, latitude -3.9839974928231157, 122.52416679312992
        });

        // create empty FeatureLayer
        const layer = new GraphicsLayer();

        let graphics = [];
        let graphic;
        for (let i = 0; i < data.length; i++) {
            graphic = generateGraphic(data[i])
            graphics.push(graphic);
        }

        layer.addMany(graphics)
        map.add(layer);
    }, [basemap, data])

    const mapStyle = {
        width: '100%',
        height: '100%',
        position: 'absolute',
        right: 0,
    };

    return (
        <SoftBox>
            <SoftBox className="map-container" ref={mapRef} sx={mapStyle} />
        </SoftBox>
    );
};

export default MapMultiple;