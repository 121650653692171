import { AddCircleOutline, Delete, People } from '@mui/icons-material'
import { Avatar, Button, Card, CardContent, Divider, Grid, IconButton, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, Typography } from '@mui/material'
import MiniStatisticsCard from 'components/Cards/StatisticsCards/MiniStatisticsCard'
import Headerx from 'components/Header/Headerx'
import SoftBox from 'components/SoftBox'
import { CustomDescrypt } from 'global/func'
import LoadingList from 'myComponent/LoadingList'
import React, { useEffect, useState } from 'react'
import { Link, Route, Routes } from 'react-router-dom'
import userStore from 'store/userStore'
import Swal from 'sweetalert2'
import Detail from './Detail'
import Tambah from './Tambah'
import { getDataWithResponse } from 'global/getFunc'
import TambahPeserta from './TambahPeserta'
import { postDelete } from 'global/postFunc'

const PertemuanTerbatas = () => {
    return (
        <Routes>
            <Route exact path='' element={<Data />} />
            {/* <Route exact path='detail' element={<Detail />} /> */}
            <Route exact path='tambah' element={<Tambah />} />
            {/* <Route exact path='tambah-peserta' element={<TambahPeserta />} /> */}
            <Route exact path='detail/*' element={<Detail />} />
            <Route exact path='tambah-peserta' element={<TambahPeserta />} />
        </Routes>
    )
}

const Data = () => {
    const encryptUser = localStorage.getItem('AuthPersist');
    const encryptUserJson = JSON.parse(encryptUser);
    const decryptedUser = encryptUserJson.state.user === null ? "" : JSON.parse(CustomDescrypt(encryptUserJson.state.user));
    const logOut = userStore((state) => state.logOut)

    const [loading, setLoading] = useState(true)
    const [tableData, setTableData] = useState([]);
    const [deleting, setDeleting] = useState(false);


    useEffect(() => {
        requestData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deleting])

    const requestData = () => {
        getDataWithResponse(
            '/entry/pt',
            decryptedUser,
            setLoading,
            setTableData,
            logOut
        )
    }

    const deleteData = (params) => {
        console.log(params);
        Swal.fire({
            title: 'Hapus Pertemuan?',
            icon: 'warning',
            text: "Data Peserta akan Otomatis Terhapus",
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ya, hapus!',
            showLoaderOnConfirm: true
        }).then((result) => {
            if (result.isConfirmed) {
                postDelete(
                    '/entry/pt/delete',
                    {
                        _method: 'DELETE',
                        id: params
                    },
                    decryptedUser,
                    setLoading,
                    () => { setDeleting(!deleting) },
                    logOut
                )
            }
        })
    }

    return (
        <>
            <Headerx title='Pertemuan Terbatas' />
            <SoftBox my={3}>
                <Grid container spacing={3} mb={3} justifyContent='center'>
                    <Grid item xs={12} lg={6}>
                        <MiniStatisticsCard
                            title={{ text: "Jumlah Pertemuan" }}
                            count={tableData.length}
                            // percentage={{ color: "success", text: "+55%" }}
                            icon={{ color: "info", component: "paid" }}
                        />
                    </Grid>
                </Grid>

                <Grid container spacing={3} justifyContent='center'>
                    <Grid item xs={12} lg={6}>
                        <Card >
                            <Grid container justifyContent="space-between" p={2}>
                                <Grid item xs="auto">
                                    <Typography>
                                        Data Pertemuan
                                    </Typography>
                                </Grid>

                                <Grid item>
                                    <Button
                                        variant="contained"
                                        endIcon={<AddCircleOutline color='white' />}
                                        // onClick={() => handleOpenDialog2()}
                                        to="/entry/pt/tambah"
                                        component={Link}
                                    >
                                        Tambah
                                    </Button>
                                </Grid>
                            </Grid>
                            {
                                loading ?
                                    (
                                        <>
                                            <LoadingList />
                                            <Divider />
                                            <LoadingList />
                                            <Divider />
                                            <LoadingList />
                                            <Divider />
                                            <LoadingList />
                                            <Divider />
                                            <LoadingList />
                                            <Divider />
                                            <LoadingList />
                                            <Divider />
                                            <LoadingList />
                                            <Divider />
                                            <LoadingList />
                                            <Divider />
                                            <LoadingList />
                                            <Divider />
                                            <LoadingList />
                                            <Divider />
                                        </>
                                    )
                                    :
                                    tableData.length > 0 ?
                                        (
                                            <Grid container justifyContent="center" p={2}>
                                                <Grid item xl={12}>
                                                    <List sx={{ bgcolor: 'background.paper' }}>
                                                        {
                                                            tableData.map((data) => (
                                                                <React.Fragment key={data.id}>
                                                                    <ListItem
                                                                        alignItems="flex-start"
                                                                        secondaryAction={
                                                                            <IconButton key={1} edge="end" aria-label="delete" onClick={() => deleteData(data.id)}>
                                                                                <Delete color='error' />
                                                                            </IconButton>

                                                                        }
                                                                    >
                                                                        <ListItemButton
                                                                            component={Link} to="/entry/pt/detail" state={data}
                                                                        >
                                                                            <ListItemAvatar>
                                                                                <Avatar>
                                                                                    <People />
                                                                                </Avatar>
                                                                            </ListItemAvatar>
                                                                            <ListItemText
                                                                                primary={
                                                                                    <Typography
                                                                                        variant="body2"
                                                                                        color="text.primary"
                                                                                    >
                                                                                        {data.judul}
                                                                                    </Typography>
                                                                                }
                                                                                secondary={
                                                                                    <Typography
                                                                                        pt={1}
                                                                                        variant="body2"
                                                                                        color="text.secondary"
                                                                                    >
                                                                                        {data.tgl}
                                                                                    </Typography>
                                                                                }
                                                                            />
                                                                        </ListItemButton>
                                                                    </ListItem>
                                                                    <Divider />
                                                                </React.Fragment>
                                                            ))
                                                        }
                                                    </List>
                                                </Grid>
                                            </Grid>
                                        )
                                        :
                                        <CardContent>
                                            <Typography variant="body2" color="text.secondary">
                                                Tidak ada data
                                            </Typography>
                                        </CardContent>

                            }
                        </Card>
                    </Grid>
                </Grid>
            </SoftBox>
        </>
    )
}

export default PertemuanTerbatas