import { ArrowBack, People } from '@mui/icons-material';
import { Avatar, Card, CardContent, CardHeader, Divider, Grid, IconButton, List, ListItem, ListItemAvatar, ListItemText, Tooltip, Typography } from '@mui/material';
import axios from 'axios';
import MiniStatisticsCard from 'components/Cards/StatisticsCards/MiniStatisticsCard';
import SoftBox from 'components/SoftBox';
import { CustomDescrypt } from 'global/func';
import { serverUrl } from 'global/variable';
import LoadingList from 'myComponent/LoadingList';
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import userStore from 'store/userStore';
import Swal from 'sweetalert2';
import SoftTypography from 'components/SoftTypography';

const Detail = () => {
    const encryptUser = localStorage.getItem('AuthPersist');
    const encryptUserJson = JSON.parse(encryptUser);
    const decryptedUser = encryptUserJson.state.user === null ? "" : JSON.parse(CustomDescrypt(encryptUserJson.state.user));
    const logOut = userStore((state) => state.logOut)

    const location = useLocation();
    const navigate = useNavigate();
    const data = location.state;

    const [loading, setLoading] = useState(true)
    const [tableData, setTableData] = useState([]);

    useEffect(() => {
        requestPeserta()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const requestPeserta = () => {
        setLoading(true)
        axios.post(
            serverUrl + '/pesertaPt',
            {
                pertemuan_terbatas: data.id
            },
            {
                headers: {
                    'Authorization': `Bearer ${decryptedUser}`,
                    'content-type': 'multipart/form-data'
                }
            })
            .then((response) => {
                // console.log(response);
                setTableData(response.data)
                setLoading(false)
            })
            .catch((error) => {
                console.log(error);
                if (error.response.status === 401 && error.response.data.messages === "Access Token Expired") {
                    logOut();
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: error.response.status + ' ' + error.response.statusText
                    })
                }
                setLoading(false)
            })
    }

    return (
        <SoftBox my={3}>
            <Grid container spacing={3} mb={3} justifyContent='center'>
                <Grid item xs={12} lg={6}>
                    <Card sx={{ minWidth: 275 }}>
                        <CardHeader
                            title={
                                <SoftTypography variant='h6'>Kembali</SoftTypography>
                            }
                            avatar={
                                <Tooltip title="Kembali">
                                    <IconButton variant="outlined" onClick={() => navigate(-1)}><ArrowBack /></IconButton>
                                </Tooltip>
                            }
                        />
                        <CardContent>
                            <Typography variant='h5' gutterBottom>
                                {data.judul}
                            </Typography>
                            <Typography variant='body2' gutterBottom>
                                Kec. {data.nama_kec}
                            </Typography>
                            <Typography sx={{ fontSize: 14 }} color="text.secondary" >
                                {data.tgl}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            <Grid container spacing={3} mb={3} justifyContent='center'>
                <Grid item xs={12} lg={6}>
                    <MiniStatisticsCard
                        title={{ text: "Jumlah Peserta" }}
                        count={tableData.length}
                        // percentage={{ color: "success", text: "+55%" }}
                        icon={{ color: "info", component: "paid" }}
                    />
                </Grid>
            </Grid>

            <Grid container spacing={3} justifyContent='center'>
                <Grid item xs={12} lg={6}>
                    <Card >
                        <Grid container justifyContent="space-between" p={2}>
                            <Grid item xs="auto">
                                <Typography>
                                    Peserta Pertemuan
                                </Typography>
                            </Grid>

                            {/* <Grid item>
                                    <Button
                                        variant="contained"
                                        endIcon={<AddCircleOutline color='white' />}
                                        // onClick={() => handleOpenDialog2()}
                                        to="/korkel/pt/tambah-peserta"
                                        component={Link}
                                        state={data}
                                    >
                                        Tambah Peserta
                                    </Button>
                                </Grid> */}
                        </Grid>
                        {
                            loading ? (
                                <>
                                    <LoadingList />
                                    <Divider />
                                    <LoadingList />
                                    <Divider />
                                    <LoadingList />
                                    <Divider />
                                    <LoadingList />
                                    <Divider />
                                    <LoadingList />
                                    <Divider />
                                    <LoadingList />
                                    <Divider />
                                    <LoadingList />
                                    <Divider />
                                    <LoadingList />
                                    <Divider />
                                    <LoadingList />
                                    <Divider />
                                    <LoadingList />
                                    <Divider />
                                </>
                            )
                                :
                                tableData.length > 0 ? (
                                    <Grid container justifyContent="center" p={2}>
                                        <Grid item xl={12}>
                                            <List sx={{ bgcolor: 'background.paper' }}>
                                                {
                                                    tableData.map((data) => (
                                                        <React.Fragment key={data.nik}>
                                                            <ListItem
                                                                alignItems="flex-start"
                                                            // secondaryAction={
                                                            //     <IconButton edge="end" aria-label="delete" onClick={() => deleteData(data.nik)}>
                                                            //         <Delete color='error' />
                                                            //     </IconButton>
                                                            // }
                                                            >
                                                                <ListItemAvatar>
                                                                    <Avatar>
                                                                        <People />
                                                                    </Avatar>
                                                                </ListItemAvatar>
                                                                <ListItemText
                                                                    primary={
                                                                        <Typography
                                                                            variant="body2"
                                                                            color="text.primary"
                                                                        >
                                                                            {data.nama}
                                                                        </Typography>
                                                                    }
                                                                    secondary={
                                                                        <Typography
                                                                            variant="body2"
                                                                            color="text.primary"
                                                                        >
                                                                            {data.nik}
                                                                        </Typography>
                                                                    }
                                                                />
                                                            </ListItem>
                                                            <Divider />
                                                        </React.Fragment>
                                                    ))
                                                }
                                            </List>
                                        </Grid>
                                    </Grid>
                                )
                                    :
                                    (
                                        <CardContent>
                                            <Typography variant="body2" color="text.secondary">
                                                Tidak ada data
                                            </Typography>
                                        </CardContent>
                                    )

                        }
                    </Card>
                </Grid>
            </Grid>
        </SoftBox>
    )
}

export default Detail