import { ArrowBack, ExpandMore, Save } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Accordion, AccordionDetails, AccordionSummary, Autocomplete, Box, Card, CardContent, CardHeader, CircularProgress, Divider, Grid, IconButton, List, ListItem, ListItemText, MenuItem, Select, TextField, Tooltip, Typography } from '@mui/material';
import axios from 'axios';
import SoftBox from 'components/SoftBox';
import SoftTypography from 'components/SoftTypography';
import { CustomDescrypt } from 'global/func';
import { postSubmit } from 'global/postFunc';
import { postDataWithResponse } from 'global/postFunc';
import { serverUrl } from 'global/variable';
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import userStore from 'store/userStore';
import Swal from 'sweetalert2';

const TambahST = () => {
    const { user, addReq } = userStore(state => ({ user: state.user, addReq: state.addReq }));
    const decryptedUser = (user === null || user === undefined || user === '') ? "" : JSON.parse(CustomDescrypt(user));
    
    const navigate = useNavigate();
    const location = useLocation();
    const data = location.state;

    const [loading, setLoading] = useState(false)
    const [listDpt, setListDpts] = useState([])
    const [dpt, setDpt] = useState({})

    const [desas, setDesas] = useState([]);
    const [kecs, setKecs] = useState([]);
    const [desa, setDesa] = useState('');
    const [kec, setKec] = useState('');

    const getDesa = async (params) => {
        const data = await axios.get(serverUrl + '/desa/' + params);
        setDesas(data.data)
    }

    const reqDpt = (params) => {
        addReq();
        setLoading(true)
        postDataWithResponse(
            '/dpt',
            {
                // status: '',
                id_kel: params
            },
            decryptedUser,
            (res) => {
                setLoading(false)
                setListDpts(res)
            },
            (error) => {
                setLoading(false)
                console.log(error)
            }
        )
    }

    const submit = (event) => {
        event.preventDefault();
        if (dpt.sumber_data_username !== '') {
            Swal.fire({
                icon: 'error',
                title: 'Ooops!!',
                text: 'Data Pernah diinput oleh ' + dpt.sumber_data_nama
            })
            return
        }
        setLoading(true)
        addReq()
        postSubmit(
            '/entry/suara-tambahan/update',
            {
                _method: 'PUT',
                nik: dpt.nik,
                sumber_data_username: data.id,
                sumber_data_nama: data.nama,
                status: 'Suara Tambahan'
            },
            decryptedUser,
            afterSubmit,
            (error) => {
                setLoading(false)
                console.log(error);
            }
        )
    }

    const afterSubmit = () => {
        navigate(-1)
    }

    const handleChangeDesa = (event) => {
        if (event.target.value === '') {
            setDesa('')
        }
        else {
            setDesa(event.target.value)
            reqDpt(event.target.value)
        }
    };

    useEffect(() => {
        getKec()
    }, [])

    const getKec = async () => {
        const kec = await axios.get(serverUrl + '/kecamatan');

        setKecs(kec.data)
    }

    const handleChangeKec = (event) => {
        if (event.target.value === '') {
            setKec('')
            setDesa('')
        }
        else {
            setKec(event.target.value)
            getDesa(event.target.value)
        }
    };

    return (
        <SoftBox mb={3}>
            <Grid container spacing={3} justifyContent='center' py={5}>
                <Grid item xs={12} sm={12} md={9}>
                    <Card >
                        <CardHeader
                            title={
                                <SoftTypography variant='h6'>Kembali</SoftTypography>
                            }
                            avatar={
                                <Tooltip title="Kembali">
                                    <IconButton variant="outlined" onClick={() => navigate(-1)}><ArrowBack /></IconButton>
                                </Tooltip>
                            }
                        />
                        {
                            loading ?
                                (
                                    <Grid container spacing={3} py={5} justifyContent="center">
                                        <Grid item>
                                            <Box sx={{ display: 'flex' }}>
                                                <CircularProgress />
                                            </Box>
                                        </Grid>
                                    </Grid>
                                )
                                :
                                (
                                    <CardContent component='form' onSubmit={submit}>
                                        <Grid container spacing={3} justifyContent="center">
                                            <Grid item xs={12} sm={12} lg={6}>
                                                <SoftBox mb={1} ml={0.5}>
                                                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                        Pilih Kecamatan
                                                    </SoftTypography>
                                                </SoftBox>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    defaultValue=''
                                                    value={kec}
                                                    label="Kecamatan"
                                                    onChange={handleChangeKec}
                                                    required
                                                >
                                                    <MenuItem value=''><em>Pilih Desa</em></MenuItem>
                                                    {
                                                        kecs.map((kec) => (
                                                            <MenuItem key={kec.id_kec} value={kec.id_kec}>{kec.nama_kec}</MenuItem>
                                                        ))
                                                    }
                                                </Select>
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={3} justifyContent="center">
                                            <Grid item xs={12} sm={12} lg={6}>
                                                <SoftBox mb={1} ml={0.5}>
                                                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                        Pilih Kelurahan
                                                    </SoftTypography>
                                                </SoftBox>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    defaultValue=''
                                                    value={desa}
                                                    label="Desa"
                                                    onChange={handleChangeDesa}
                                                    required
                                                >
                                                    <MenuItem value=''><em>Pilih Desa</em></MenuItem>
                                                    {
                                                        desas.map((des) => (
                                                            <MenuItem key={des.id_desa} value={des.id_desa}>{des.nama_desa}</MenuItem>
                                                        ))
                                                    }
                                                </Select>
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={3} justifyContent="center">
                                            <Grid item xs={12} sm={12} lg={6}>
                                                <SoftBox mb={1} ml={0.5}>
                                                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                        NIK
                                                    </SoftTypography>
                                                </SoftBox>
                                                <Autocomplete
                                                    id="combo-box-demo"
                                                    options={listDpt}
                                                    fullWidth
                                                    getOptionLabel={(option) => option.nik + ' ' + option.nama}
                                                    onChange={(event, newValue) => {
                                                        newValue !== null && setDpt(newValue)
                                                    }}
                                                    onInputChange={(event, newInputValue) => {
                                                        newInputValue === "" && setDpt({})
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                        </Grid>
                                        {
                                            dpt.nik !== undefined &&
                                            <Grid container spacing={3} pt={3} justifyContent="center">
                                                <Grid item xs={12} sm={12} md={10} lg={6}>
                                                    <Accordion>
                                                        <AccordionSummary
                                                            expandIcon={<ExpandMore />}
                                                            aria-controls="panel1-content"
                                                            id="panel1-header"
                                                        >
                                                            <Typography variant='button'>
                                                                Detail DPT
                                                            </Typography>
                                                        </AccordionSummary>
                                                        <AccordionDetails>
                                                            <List sx={{ bgcolor: 'background.paper' }}>
                                                                <ListItem>
                                                                    <ListItemText primary={
                                                                        <Typography
                                                                            variant="body2"
                                                                            color="text.primary"
                                                                        >
                                                                            NIK
                                                                        </Typography>
                                                                    }
                                                                        secondary={
                                                                            <Typography
                                                                                variant="body2"
                                                                                color="text.primary"
                                                                            >
                                                                                {dpt.nik ? dpt.nik : '-'}
                                                                            </Typography>
                                                                        } />
                                                                </ListItem>
                                                                <Divider />
                                                                <ListItem>
                                                                    <ListItemText primary={
                                                                        <Typography
                                                                            variant="body2"
                                                                            color="text.primary"
                                                                        >
                                                                            No. KK
                                                                        </Typography>
                                                                    }
                                                                        secondary={
                                                                            <Typography
                                                                                variant="body2"
                                                                                color="text.primary"
                                                                            >
                                                                                {dpt.no_kk ? dpt.no_kk : '-'}
                                                                            </Typography>
                                                                        } />
                                                                </ListItem>
                                                                <Divider />
                                                                <ListItem>
                                                                    <ListItemText primary={
                                                                        <Typography
                                                                            variant="body2"
                                                                            color="text.primary"
                                                                        >
                                                                            Nama
                                                                        </Typography>
                                                                    }
                                                                        secondary={
                                                                            <Typography
                                                                                variant="body2"
                                                                                color="text.primary"
                                                                            >
                                                                                {dpt.nama ? dpt.nama : '-'}
                                                                            </Typography>
                                                                        } />
                                                                </ListItem>
                                                                <Divider />
                                                                <ListItem>
                                                                    <ListItemText
                                                                        primary={
                                                                            <Typography
                                                                                variant="body2"
                                                                                color="text.primary"
                                                                            >
                                                                                Tempat/Tgl Lahir
                                                                            </Typography>
                                                                        }
                                                                        secondary={
                                                                            <Typography
                                                                                variant="body2"
                                                                                color="text.primary"
                                                                            >
                                                                                {dpt.tempat_lahir ? dpt.tempat_lahir + ', ' + dpt.tgl_lahir : '-'}
                                                                            </Typography>
                                                                        }
                                                                    />
                                                                </ListItem>
                                                                <Divider />
                                                                <ListItem>
                                                                    <ListItemText
                                                                        primary={
                                                                            <Typography
                                                                                variant="body2"
                                                                                color="text.primary"
                                                                            >
                                                                                Status Kawin
                                                                            </Typography>
                                                                        }
                                                                        secondary={
                                                                            <Typography
                                                                                variant="body2"
                                                                                color="text.primary"
                                                                            >
                                                                                {
                                                                                    dpt.stts_kawin
                                                                                }
                                                                            </Typography>
                                                                        }
                                                                    />
                                                                </ListItem>
                                                                <Divider />
                                                                <ListItem>
                                                                    <ListItemText
                                                                        primary={
                                                                            <Typography
                                                                                variant="body2"
                                                                                color="text.primary"
                                                                            >
                                                                                Jenis Kelamin
                                                                            </Typography>
                                                                        }
                                                                        secondary={
                                                                            <Typography
                                                                                variant="body2"
                                                                                color="text.primary"
                                                                            >
                                                                                {dpt.jenkel === 'L' ? 'Laki-laki' : 'Perempuan'}
                                                                            </Typography>
                                                                        }
                                                                    />
                                                                </ListItem>
                                                                <Divider />
                                                                <ListItem>
                                                                    <ListItemText
                                                                        primary={
                                                                            <Typography
                                                                                variant="body2"
                                                                                color="text.primary"
                                                                            >
                                                                                Alamat
                                                                            </Typography>
                                                                        }
                                                                        secondary={
                                                                            <Typography
                                                                                variant="body2"
                                                                                color="text.primary"
                                                                            >
                                                                                {dpt.alamat ? `${dpt.alamat} RT. ${dpt.rt} RW. ${dpt.rw}, Kel. ${dpt.nama_kel}, Kec. ${dpt.nama_kec}, Kota. ${dpt.nama_kab}` : '-'}
                                                                            </Typography>
                                                                        } />
                                                                </ListItem>
                                                                <Divider />
                                                                <ListItem>
                                                                    <ListItemText
                                                                        primary={
                                                                            <Typography
                                                                                variant="body2"
                                                                                color="text.primary"
                                                                            >
                                                                                RT / RW
                                                                            </Typography>
                                                                        }
                                                                        secondary={
                                                                            <Typography
                                                                                variant="body2"
                                                                                color="text.primary"
                                                                            >
                                                                                RT. {dpt.rt}, RW. {dpt.rw}
                                                                            </Typography>
                                                                        } />
                                                                </ListItem>
                                                                <Divider />
                                                                <ListItem>
                                                                    <ListItemText primary={
                                                                        <Typography
                                                                            variant="body2"
                                                                            color="text.primary"
                                                                        >
                                                                            TPS
                                                                        </Typography>
                                                                    }
                                                                        secondary={
                                                                            <Typography
                                                                                variant="body2"
                                                                                color="text.primary"
                                                                            >
                                                                                {dpt.nama_tps}
                                                                            </Typography>
                                                                        } />
                                                                </ListItem>
                                                                <Divider />
                                                            </List>
                                                        </AccordionDetails>
                                                    </Accordion>



                                                </Grid>
                                            </Grid>
                                        }
                                        <Grid container justifyContent='center' mt={2}>
                                            <Grid item xs={12} sm={12} lg={6}>
                                                <Grid container justifyContent='flex-end'>
                                                    <Grid item>
                                                        <SoftBox mb={1} ml={0.5}>
                                                            <LoadingButton
                                                                loading={loading}
                                                                loadingPosition="start"
                                                                startIcon={<Save />}
                                                                variant="contained"
                                                                type='submit'
                                                            >
                                                                Save
                                                            </LoadingButton>
                                                        </SoftBox>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                )
                        }

                    </Card>
                </Grid>
            </Grid>
        </SoftBox>
    )
}

export default TambahST