import { FormControl, MenuItem, Select } from '@mui/material'
import axios from 'axios'
import SoftBox from 'components/SoftBox'
import SoftTypography from 'components/SoftTypography'
import React, { useEffect, useState } from 'react'
import { serverUrl } from '../global/variable'

const SelectKec = ({ formData, setFormData, disabled = false, setKecs }) => {
    const [kecamatans, setKecamatans] = useState([])

    useEffect(() => {
        getKec()
    }, [])

    useEffect(() => {
        setKecs && setKecs(kecamatans)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [kecamatans])

    const getKec = async () => {
        const kec = await axios.get(serverUrl + '/kecamatan');

        setKecamatans(kec.data)
    }

    const handleChangeKec = (event) => {
        if (event.target.value === '') {
            setFormData({ ...formData, id_kec: '', id_kel: '', id_tps: '', nama_kec: '', nama_kel: '', nama_tps: '', dtd_username: '' })
        }
        else {
            let resultObject = kecamatans.find(x => x.id_kec === event.target.value)
            setFormData({ ...formData, id_kec: event.target.value, nama_kec: resultObject.nama_kec, id_kel: '', id_tps: '', nama_kel: '', nama_tps: '', dtd_username: '' })
        }
    };

    return (
        <FormControl fullWidth>
            <SoftBox mb={1} ml={0.5}>
                <SoftTypography component="label" variant="caption" fontWeight="bold">
                    Pilih Kecamatan
                </SoftTypography>
            </SoftBox>
            <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                defaultValue=''
                value={formData.id_kec || ""}
                label="Kecamatan"
                onChange={handleChangeKec}
                required
                disabled={disabled}
            >
                <MenuItem value=''><em>Pilih Kecamatan</em></MenuItem>
                {
                    kecamatans.map((kec) => (
                        <MenuItem key={kec.id_kec} value={kec.id_kec}>{kec.nama_kec}</MenuItem>
                    ))
                }
            </Select>
        </FormControl>
    )
}

export default SelectKec