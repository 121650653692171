import { Button, Card, CardContent, CardHeader, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from '@mui/material'
import axios from 'axios'
import MiniStatisticsCard from 'components/Cards/StatisticsCards/MiniStatisticsCard'
import SoftBox from 'components/SoftBox'
import { CustomDescrypt } from 'global/func'
import { serverUrl } from 'global/variable'
import React, { useEffect, useState } from 'react'
import { Route, Routes } from 'react-router-dom'
import userStore from 'store/userStore'
import Swal from 'sweetalert2'
import MyTable from 'myComponent/Table/MyTable'
import { AddCircleOutline } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import SoftTypography from 'components/SoftTypography'
import SoftInput from 'components/SoftInput'

const JenisBantuan = () => {
    return (
        <Routes>
            <Route exact path='' element={<Data />} />
            {/* <Route exact path='detail' element={<Detail />} /> */}
            {/* <Route exact path='tambah' element={<Tambah />} /> */}
            {/* <Route exact path='edit' element={<Edit />} /> */}
        </Routes>
    )
}

const Data = () => {
    const encryptUser = localStorage.getItem('AuthPersist');
    const encryptUserJson = JSON.parse(encryptUser);
    const decryptedUser = encryptUserJson.state.user === null ? "" : JSON.parse(CustomDescrypt(encryptUserJson.state.user));
    const logOut = userStore((state) => state.logOut)

    const [tableData, setTableData] = useState([]);
    const [deleting, setDeleting] = useState(false)

    const [loading, setLoading] = useState(true)
    const [openDialog, setOpenDialog] = useState(false)

    const [formData, setFormData] = useState({
        nama: ''
    })

    useEffect(() => {
        requestData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deleting])

    const requestData = () => {
        // const decodedUser = decodeToken(decryptedUser);
        setLoading(true)
        axios.get(
            serverUrl + '/admin/jenis-bantuan',
            {
                headers: {
                    'Authorization': `Bearer ${decryptedUser}`,
                    'content-type': 'multipart/form-data'
                }
            })
            .then((response) => {
                // console.log(response);
                setTableData(response.data)
                setLoading(false)
            })
            .catch((error) => {
                console.log(error);
                if (error.response.status === 401 && error.response.data.messages === "Access Token Expired") {
                    logOut();
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: error.response.status + ' ' + error.response.statusText
                    })
                }
                setLoading(false)
            })
    }

    const columns = [
        //fields = { ['no_kk', 'nik_kepala', 'nama_kepala', 'pekerjaan', 'jml_anggota', 'no_hp', 'created_at', 'nama_petugas']}
        //headers = { ['No KK', 'NIK KK', 'Nama KK', 'Pekerjaan', 'Jml Anggota', 'No. HP', 'Jam Input', 'Tim']}

        {
            accessorKey: 'nama',
            header: 'Nama'
        }
    ]

    const handleOpenDialog = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const submit = (event) => {
        event.preventDefault();
        setLoading(true)
        // setFormData({ ...formData, tgl: tanggal.format('YYYY-MM-DD') })
        axios.post(
            serverUrl + '/admin/jenis-bantuan/insert',
            formData,
            {
                headers: {
                    'Authorization': `Bearer ${decryptedUser}`,
                    'content-type': 'multipart/form-data'
                }
            })
            .then((response) => {
                console.log(response);
                Swal.fire({
                    icon: 'success',
                    title: 'Berhasil!',
                    text: response.data
                })
                // navigate(-1)
                setDeleting(!deleting)
                setLoading(false)
                handleCloseDialog()
                setFormData({ ...formData, nama: '' })
            })
            .catch((error) => {
                console.log(error);
                if (error.response.status === 401 && error.response.data.messages === "Access Token Expired") {
                    logOut();
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: error.response.data.messages.error
                    })
                }
                setDeleting(!deleting)
                setLoading(false)
            })
    }

    return (
        <SoftBox my={3}>
            <Grid container spacing={3} mb={3} justifyContent='center'>
                <Grid item xs={12} lg={12}>
                    <MiniStatisticsCard
                        title={{ text: "Total Data" }}
                        count={tableData.length}
                        // percentage={{ color: "success", text: "+55%" }}
                        icon={{ color: "info", component: "paid" }}
                    />
                </Grid>
            </Grid>

            <Grid container spacing={3} justifyContent='center'>
                <Grid item xs={12} lg={12}>
                    <Card>
                        <CardHeader
                            title={
                                <Typography>
                                    Tabel Jenis Bantuan
                                </Typography>
                            }
                            action={
                                <Button
                                    variant="contained"
                                    endIcon={<AddCircleOutline color='white' />}
                                    onClick={handleOpenDialog}
                                    // to="/admin/jenis-bantuan/tambah"
                                    // component={Link}
                                    disabled={tableData.length >= 10}
                                >
                                    Tambah
                                </Button>
                            }
                        />
                        <CardContent>
                            <MyTable
                                data={tableData ?? []}
                                // headers={['No KK', 'NIK KK', 'Nama KK', 'Pekerjaan', 'Jml Anggota', 'No. HP', 'Jam Input', 'Tim']}
                                // fields={['no_kk', 'nik_kepala', 'nama_kepala', 'pekerjaan', 'jml_anggota', 'no_hp', 'created_at', 'nama_petugas']}
                                columns={columns}
                                deleting={deleting}
                                setDeleting={setDeleting}
                                // columnVisibility={columnVisibility}
                                deleteUrl={serverUrl + '/admin/jenis-bantuan/delete'}
                                deleteField='id'
                                // detailUrl={'/detail'}
                                state={{ isLoading: loading }}
                            />
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            <Dialog open={openDialog} onClose={handleCloseDialog} fullWidth>
                <DialogTitle>Tambah Jenis Bansos</DialogTitle>
                <DialogContent>
                    <SoftBox mb={2} justifyContent="center">
                        <SoftBox mb={1} ml={0.5}>
                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                Jenis Bansos
                            </SoftTypography>
                        </SoftBox>
                        <SoftInput
                            type="text"
                            defaultValue={formData.nama}
                            onChange={(event) => setFormData({ ...formData, nama: event.target.value })}
                            autoComplete='off'
                            required
                        />
                    </SoftBox>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog}>Cancel</Button>
                    <LoadingButton loading={loading} onClick={submit}>Save</LoadingButton>
                </DialogActions>
            </Dialog>
        </SoftBox>

    )
}

export default JenisBantuan