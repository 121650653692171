import { HighchartsReact } from 'highcharts-react-official'
import Highcharts from 'highcharts'
import highchartsMore from "highcharts/highcharts-more.js"
import solidGauge from "highcharts/modules/solid-gauge.js";
import { useSoftUIController } from 'context';
import { useEffect, useState } from 'react';

require("highcharts/modules/exporting")(Highcharts);
highchartsMore(Highcharts);
solidGauge(Highcharts);

const ActivityGaugeChart = ({ title, exporting = true, tooltip = true, data, background }) => {
    const [controller] = useSoftUIController();
    const { darkMode } = controller;
    const [options, setOptions] = useState({})

    useEffect(() => {
        setOptions({
            chart: {
                type: 'solidgauge',
                height: '110%',
                backgroundColor: darkMode ? '#111c44' : '#ffffff'
                // margin: [0, 0, 0, 0]
            },
            title: {
                align: 'center',
                text: title,
                style: {
                    color: darkMode ? '#ffffff' : '#67748e'
                }
            },
            exporting: {
                enabled: exporting
            },
            tooltip: {
                enabled: tooltip,
                borderWidth: 0,
                backgroundColor: 'none',
                shadow: false,
                style: {
                    fontSize: '1rem'
                },
                positioner: function (labelWidth) {
                    return {
                        x: (this.chart.chartWidth - labelWidth) / 2,
                        y: (this.chart.plotHeight / 2) - 5
                    };
                },
                useHTML: true,
                formatter: function () {
                    return '<table>' +
                        '<tr><td style="text-align: center; color: ' + this.point.textColor + '">' + this.series.name + '</td></tr>' +
                        '<tr><td style="text-align: center; font-size:1.5rem; color: ' + this.point.color + '; font-weight: bold"">' + this.point.angka.toLocaleString() + '</td></tr>' +
                        '<tr><td style="text-align: center; color: ' + this.point.textColor + '">(' + this.point.y.toFixed(2) + '% ' + this.point.capt + ')</td></tr>' +
                        '</table>'
                }
            },
            pane: {
                startAngle: 0,
                endAngle: 360,
                background: background
            },
            credits: {
                enabled: false
            },
            yAxis: {
                min: 0,
                max: 100,
                lineWidth: 0,
                tickPositions: []
            },
            plotOptions: {
                solidgauge: {
                    dataLabels: {
                        enabled: data[0].dataLabels
                    },
                    linecap: 'round',
                    stickyTracking: false,
                    rounded: true,
                },
            },
            series: data
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, darkMode])

    return (
        <HighchartsReact
            highcharts={Highcharts}
            options={options}

        />
    )
}

export default ActivityGaugeChart