import { Card, CardContent, CardHeader, Grid, Typography } from '@mui/material'
import axios from 'axios'
import MiniStatisticsCard from 'components/Cards/StatisticsCards/MiniStatisticsCard'
import SoftBox from 'components/SoftBox'
import { CustomDescrypt } from 'global/func'
import { serverUrl } from 'global/variable'
import React, { useEffect, useState } from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'
import userStore from 'store/userStore'
import Swal from 'sweetalert2'
import Headerx from 'components/Header/Headerx'
import SelectKec from 'myComponent/SelectKec'
import SelectDesa from 'myComponent/SelectDesa'
import MyTable from 'myComponent/Table/MyTable'

const Korkel = () => {
    return (
        <Routes>
            <Route exact path='' element={<Data />} />
            {/* <Route exact path='detail' element={<Detail />} /> */}
            {/* <Route exact path='tambah' element={<Tambah />} /> */}
            {/* <Route exact path='edit' element={<Edit />} /> */}
        </Routes>
    )
}

const Data = () => {
    const encryptUser = localStorage.getItem('AuthPersist');
    const encryptUserJson = JSON.parse(encryptUser);
    const decryptedUser = encryptUserJson.state.user === null ? "" : JSON.parse(CustomDescrypt(encryptUserJson.state.user));
    const logOut = userStore((state) => state.logOut)

    const location = useLocation();
    const locationState = location.state

    const [tableData, setTableData] = useState([]);
    const [deleting, setDeleting] = useState(false)

    const [loading, setLoading] = useState(true)
    // const [listKel, setListKel] = useState([])
    // const [listKec, setListKec] = useState([])

    const [formData, setFormData] = useState({
        // semua: true,
        // tanggal: tanggal,
        // id_kab: idKab,
        id_kec: locationState !== null ? locationState : '',
        id_kel: ''
    })

    // useEffect(() => {
    //     setListKel([])
    //     desas.map((ds) => (
    //         setListKel(oldArray => [...oldArray, ds.nama_desa])
    //     ))
    //     // console.log(listKel);
    // }, [desas])

    // useEffect(() => {
    //     setListKec([])
    //     kecs.map((ds) => (
    //         setListKec(oldArray => [...oldArray, ds.nama_kec])
    //     ))
    //     // console.log(listKec);
    // }, [])

    // useEffect(() => {
    //     console.log(formData);
    // }, [formData])

    useEffect(() => {
        requestData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deleting, formData])

    const requestData = () => {
        // const decodedUser = decodeToken(decryptedUser);
        setLoading(true)
        axios.post(
            serverUrl + '/admin/korkel',
            formData,
            {
                headers: {
                    'Authorization': `Bearer ${decryptedUser}`,
                    'content-type': 'multipart/form-data'
                }
            })
            .then((response) => {
                // console.log(response);
                setTableData(response.data)
                setLoading(false)
            })
            .catch((error) => {
                console.log(error);
                if (error.response.status === 401 && error.response.data.messages === "Access Token Expired") {
                    logOut();
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: error.response.status + ' ' + error.response.statusText
                    })
                }
                setLoading(false)
            })
    }

    const columns = [
        //fields = { ['no_kk', 'nik_kepala', 'nama_kepala', 'pekerjaan', 'jml_anggota', 'no_hp', 'created_at', 'nama_petugas']}
        //headers = { ['No KK', 'NIK KK', 'Nama KK', 'Pekerjaan', 'Jml Anggota', 'No. HP', 'Jam Input', 'Tim']}
        {
            accessorKey: 'username',
            header: 'Username',
            filterVariant: 'text',
        },
        {
            accessorKey: 'nama',
            header: 'Nama'
        },
        {
            accessorKey: 'password',
            header: 'Password'
        },
        {
            accessorKey: 'id_kec',
            header: 'ID_KEC'
        },
        {
            accessorKey: 'nama_kec',
            header: 'Kec',
            // filterVariant: 'select',
            // filterSelectOptions: listKec,
        },
        {
            accessorKey: 'id_kel',
            header: 'ID_KEL',
        },
        {
            accessorKey: 'nama_kel',
            header: 'Kel',
            // filterVariant: 'select',
            // filterSelectOptions: listKel,
        },
    ]

    const columnVisibility = {
        // username: false,
        // password: false,
        id_kec: false,
        // nama_kec: false,
        id_kel: false,
        // nama_kel: false,
        // dtd_nama: false,
        // tgl_lahir: false
    }

    return(
        <>
            <Headerx title='Admin' />
            <SoftBox my={3}>
                <Grid container spacing={3} mb={3} justifyContent='center'>
                    <Grid item xs={12} lg={12}>
                        <MiniStatisticsCard
                            title={{ text: "Total Data" }}
                            count={tableData.length}
                            // percentage={{ color: "success", text: "+55%" }}
                            icon={{ color: "info", component: "paid" }}
                        />
                    </Grid>
                </Grid>

                <Grid container spacing={3} justifyContent='center'>
                    <Grid item xs={12} lg={12}>
                        <Card>
                            <CardHeader
                                title={
                                    <Typography>
                                        Tabel Relawan Brigade
                                    </Typography>
                                }
                            />
                            <CardContent>
                                <Grid container spacing={2} mb={2}>
                                    <Grid item xs={12} lg={4}>
                                        <SelectKec formData={formData} setFormData={setFormData}  />
                                    </Grid>
                                    <Grid item xs={12} lg={4}>
                                        <SelectDesa formData={formData} setFormData={setFormData} idKec={formData.id_kec}  />
                                    </Grid>
                                </Grid>
                                <Grid container justifyContent="space-between" >
                                    <Grid item xs={12}>
                                        <MyTable
                                            data={tableData ?? []}
                                            // headers={['No KK', 'NIK KK', 'Nama KK', 'Pekerjaan', 'Jml Anggota', 'No. HP', 'Jam Input', 'Tim']}
                                            // fields={['no_kk', 'nik_kepala', 'nama_kepala', 'pekerjaan', 'jml_anggota', 'no_hp', 'created_at', 'nama_petugas']}
                                            columns={columns}
                                            deleting={deleting}
                                            setDeleting={setDeleting}
                                            columnVisibility={columnVisibility}
                                            deleteUrl={serverUrl + '/admin/korkel/delete'}
                                            deleteField='username'
                                            detailUrl={'/detail'}
                                            state={{ isLoading: loading }}
                                        />
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </SoftBox>
        </>
    )
}

export default Korkel