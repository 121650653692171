/* eslint-disable react/jsx-pascal-case */
import { Delete, Download, Edit, Info, Print } from '@mui/icons-material'
import { Box, IconButton, Tooltip } from '@mui/material'
import { MRT_ShowHideColumnsButton, MRT_ToggleDensePaddingButton, MRT_ToggleFiltersButton, MRT_ToggleFullScreenButton, MRT_ToggleGlobalFilterButton, MaterialReactTable, useMaterialReactTable } from 'material-react-table'
import { Link } from 'react-router-dom';

const MyTable = ({
    data = [],
    columns,
    baseUrl,
    editUrl,
    deleteFunc,
    columnVisibility = null,
    exportFunc
}) => {
    const table = useMaterialReactTable({
        data: data,
        columns: columns,
        initialState: {
            showColumnFilters: true,
            columnVisibility: columnVisibility,
        },
        enableRowActions: true,
        positionActionsColumn: 'first',
        displayColumnDefOptions: {
            'mrt-row-actions': {
                header: '', //change header text
                size: 100, //make actions column wider
                alignment: 'left'
            },
        },
        renderRowActions: ({ row, table }) => (
            <Box >
                <Tooltip title="Detail">
                    <IconButton
                        id="basic-button"
                        color='primary'
                        component={Link}
                        to={baseUrl + '/detail'}
                        state={row.original}
                    >
                        <Info fontSize='small' />
                    </IconButton>
                </Tooltip>
                {
                    deleteFunc &&
                    <Tooltip title="Detail">
                        <IconButton
                            id="basic-button"
                            color='error'
                            onClick={() => deleteFunc(row.original)}
                        >
                            <Delete fontSize='small' />
                        </IconButton>
                    </Tooltip>
                }
                {
                    editUrl &&
                    <IconButton
                        id="basic-button"
                        color='warning'
                        component={Link}
                        to={editUrl}
                        state={row.original}
                    >
                        <Edit fontSize='small' />
                    </IconButton>
                }
            </Box>
        ),
        muiTableHeadCellProps: {
            sx: {
                fontSize: '0.875rem !important',
            }
        },
        muiTableBodyCellProps: {
            sx: {
                fontWeight: 'normal',
                fontSize: '0.875rem !important',
            },
        },
        muiTablePaginationProps: {
            rowsPerPageOptions: [10, 20, 50, { label: 'All', value: data.length }],
            showFirstButton: true,
            showLastButton: true,
        },
        renderToolbarInternalActions: ({ table }) => (
            <Box>
                {
                    exportFunc &&
                    <Tooltip title="Export">
                        <IconButton
                            onClick={() => {
                                exportFunc(data)
                            }}
                        >
                            <Download />
                        </IconButton>
                    </Tooltip>
                }
                <MRT_ToggleGlobalFilterButton table={table} />
                <MRT_ToggleFiltersButton table={table} />
                <MRT_ShowHideColumnsButton table={table} />
                <MRT_ToggleDensePaddingButton table={table} />
                <MRT_ToggleFullScreenButton table={table} />
                {/* <MRT_GlobalFilterTextField table={table} /> */}
            </Box>
        )
    }, [data, columns])

    // const table = useMaterialReactTable({
    //     data,
    //     columns,
    //     initialState: {
    //         showColumnFilters: true,
    //         columnVisibility: columnVisibility,
    //         // pagination: { pageSize: 10, pageIndex:1 }
    //     },
    //     enableRowActions: deleteUrl !== null || detailUrl !== null || editUrl !== null,
    //     // onColumnFiltersChange={setColumnFilters} 
    //     positionActionsColumn: 'first',
    //     displayColumnDefOptions: {
    //         'mrt-row-actions': {
    //             header: '', //change header text
    //             size: 50, //make actions column wider
    //             alignment: 'left'
    //         },
    //     },
    //     renderRowActionMenuItems: ({ row }) => [
    //         detailUrl &&
    //         <MenuItem
    //             key={0}
    //             component={Link} to={pathname + detailUrl} state={row.original}
    //             sx={{ m: 0 }}
    //             disabled={detailUrl === null}
    //         >
    //             <ListItemIcon>
    //                 <Info color={detailUrl ? 'info' : 'disabled'} />
    //             </ListItemIcon>
    //             Detail
    //         </MenuItem>,
    //         editUrl &&
    //         <MenuItem
    //             key={1}
    //             component={Link} to={pathname + editUrl} state={row.original}
    //             sx={{ m: 0 }}
    //             disabled={editUrl === null}
    //         >
    //             <ListItemIcon>
    //                 <Edit color={editUrl ? 'primary' : 'disabled'} />
    //             </ListItemIcon>
    //             Edit
    //         </MenuItem>,
    //         deleteUrl &&
    //         <MenuItem
    //             key={2}
    //             onClick={() => {
    //                 // View profile logic...
    //                 hapusData(deleteField, row.original[deleteField], deleteUrl);
    //             }}
    //             sx={{ m: 0 }}
    //             disabled={deleteUrl === null}
    //         >
    //             <ListItemIcon>
    //                 <Delete color={deleteUrl ? 'error' : 'disabled'} />
    //             </ListItemIcon>
    //             Hapus
    //         </MenuItem>,
    //     ],

    //     muiTablePaginationProps: {
    //         rowsPerPageOptions: [10, 20, 50, { label: 'All', value: data.length }],
    //         showFirstButton: true,
    //         showLastButton: true,
    //     },
    //     renderTopToolbarCustomActions: ({ table }) => (
    //         <Box
    //             sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}
    //         >
    //             <Button
    //                 color="primary"
    //                 //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
    //                 onClick={handleExportData}
    //                 startIcon={<FileDownload />}
    //                 variant="contained"
    //             >
    //                 Export All
    //             </Button>
    //             <Button
    //                 disabled={table.getPrePaginationRowModel().rows.length === 0}
    //                 //export all rows, including from the next page, (still respects filtering and sorting)
    //                 onClick={() =>
    //                     handleExportRows(table.getPrePaginationRowModel().rows)
    //                 }
    //                 startIcon={<FileDownload />}
    //                 variant="contained"
    //             >
    //                 Export Rows
    //             </Button>
    //         </Box>
    //     ),
    //     renderToolbarInternalActions: ({ table }) => (
    //         <Box>
    //             <IconButton
    //                 onClick={() => {
    //                     window.print();
    //                 }}
    //             >
    //                 <Print />
    //             </IconButton>
    //             <MRT_ToggleGlobalFilterButton table={table} />
    //             <MRT_ToggleFiltersButton table={table} />
    //             <MRT_ShowHideColumnsButton table={table} />
    //             <MRT_ToggleDensePaddingButton table={table} />
    //             <MRT_ToggleFullScreenButton table={table} />
    //             {/* <MRT_GlobalFilterTextField table={table} /> */}
    //         </Box>
    //     ),
    // }, [])

    return (
        <MaterialReactTable table={table} />
    )
}

export default MyTable