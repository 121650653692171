import { Card, CardContent, CardHeader, Grid, Typography } from '@mui/material'
import axios from 'axios'
import MiniStatisticsCard from 'components/Cards/StatisticsCards/MiniStatisticsCard'
import SoftBox from 'components/SoftBox'
import { CustomDescrypt } from 'global/func'
import { serverUrl } from 'global/variable'
import React, { useEffect, useState } from 'react'
import { Route, Routes } from 'react-router-dom'
import userStore from 'store/userStore'
import Swal from 'sweetalert2'
import Detail from './Detail'
import ServerSideTable from 'myComponent/Table/ServerSideTable'
import { postDataWithResponse } from 'global/postFunc'
import { postDelete } from 'global/postFunc'

const PendataanDTD = () => {
    return (
        <Routes>
            <Route exact path='' element={<Data />} />
            <Route exact path='detail' element={<Detail />} />
            {/* <Route exact path='tambah' element={<Tambah />} /> */}
            {/* <Route exact path='edit' element={<Edit />} /> */}
        </Routes>
    )
}

const Data = () => {
    const { user, addReq } = userStore(state => ({ user: state.user, addReq: state.addReq }));
    const decryptedUser = (user === null || user === undefined || user === '') ? "" : JSON.parse(CustomDescrypt(user));

    const [tableData, setTableData] = useState([]);
    const [deleting, setDeleting] = useState(false)

    const [loading, setLoading] = useState(true)

    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 10, //customize the default page size
    });
    // const [globalFilter, setGlobalFilter] = useState('')
    const [columnFilter, setColumnFilter] = useState([])

    const [listKecs, setListKecs] = useState([])
    const [listKels, setListKels] = useState([])
    const [listVg, setListVg] = useState([])

    useEffect(() => {
        getKec()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getKec = async () => {
        addReq()
        const kec = await axios.get(serverUrl + '/kecamatan');
        setListKecs(kec.data)
    }

    useEffect(() => {
        let object = columnFilter.find(obj => {
            return obj.id === 'id_kec'
        })

        if (object !== undefined) {
            console.log(object);

            getDesa(object.value)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [columnFilter])
    
    useEffect(() => {
        let object = columnFilter.find(obj => {
            return obj.id === 'id_kel'
        })

        if (object !== undefined) {
            console.log(object);

            getVg(object.value)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [columnFilter])

    const getDesa = async (params) => {
        addReq()
        const data = await axios.get(serverUrl + '/desa/' + params);
        setListKels(data.data)
        console.log(data);
    }
    
    const getVg = async (params) => {
        addReq()
        const data = await axios.get(serverUrl + '/get-vg-by-desa/' + params);
        setListVg(data.data)
    }

    useEffect(() => {
        console.log(columnFilter);

        requestData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [columnFilter, pagination, deleting])

    const requestData = () => {
        const frm = {
            pagination: pagination,
            columnFilter: columnFilter
        };
        setLoading(true)
        addReq()
        postDataWithResponse(
            '/admin/dtd-serverside',
            frm,
            decryptedUser,
            (res) => {
                setLoading(false)
                setTableData(res)
            },
            (error) => {
                setLoading(false)
                console.log(error)
            }
        )
    }

    const [listKecFilter, setListKecFilter] = useState([])
    const [listKelFilter, setListKelFilter] = useState([])
    const [listVgFilter, setListVgFilter] = useState([])

    useEffect(() => {
        setListKecFilter([])
        listKecs.forEach((item, index) => {
            let x = { label: item.nama_kec, value: item.id_kec };
            setListKecFilter(listKecFilter => [...listKecFilter, x])
        })
    }, [listKecs])

    useEffect(() => {
        setListKelFilter([])
        listKels.forEach((item, index) => {
            let x = { label: item.nama_desa, value: item.id_desa };
            setListKelFilter(listKelFilter => [...listKelFilter, x])
        })

    }, [listKels])
    
    useEffect(() => {
        setListVgFilter([])
        listVg.forEach((item, index) => {
            let x = { label: item.nik, value: item.nama };
            setListKelFilter(listKelFilter => [...listKelFilter, x])
        })
    }, [listVg])

    const columns = [
        //fields = { ['no_kk', 'nik_kepala', 'nama_kepala', 'pekerjaan', 'jml_anggota', 'no_hp', 'created_at', 'nama_petugas']}
        //headers = { ['No KK', 'NIK KK', 'Nama KK', 'Pekerjaan', 'Jml Anggota', 'No. HP', 'Jam Input', 'Tim']}
        {
            accessorKey: 'no_kk',
            header: 'No KK',
            filterVariant: 'text',
        },
        {
            accessorKey: 'nik',
            header: 'NIK'
        },
        {
            accessorKey: 'nama',
            header: 'Nama'
        },
        {
            accessorKey: 'id_kec',
            header: 'Kec',
            Cell: ({ cell }) => (
                <Typography fontSize='small' variant='body2'>{cell.row.original.nama_kec}</Typography>
            ),
            filterSelectOptions: listKecFilter,
            filterVariant: 'select',
        },
        {
            accessorKey: 'id_kel',
            header: 'Kel/Desa',
            Cell: ({ cell }) => (
                <Typography fontSize='small' variant='body2'>{cell.row.original.nama_kel}</Typography>
            ),
            filterSelectOptions: listKelFilter,
            filterVariant: 'select',
        },
        {
            accessorKey: 'tempat_lahir',
            header: 'Tmp Lahir'
        },
        {
            accessorKey: 'tgl_lahir',
            header: 'Tgl Lahir'
        },
        {
            accessorKey: 'sumber_data_username',
            header: 'Sumber Data',
            Cell: ({ cell }) => (
                <Typography fontSize='small' variant='body2'>{cell.row.original.sumber_data_nama}</Typography>
            ),
            filterSelectOptions: listVgFilter,
            filterVariant: 'select',
        },
    ]

    const columnVisibility = {
        no_kk: false,
        tempat_lahir: false,
        tgl_lahir: false,
        // tgl_lahir: false
    }

    const deleteData = (params) => {
        Swal.fire({
            title: 'Hapus Data?',
            icon: 'warning',
            text: "Hapus Data " + params.nik + '?',
            showCancelButton: true,
            confirmButtonText: 'Hapus',
            cancelButtonText: 'Batal',
            confirmButtonColor: '#dd3333',
            // cancelButtonColor: '#d33',
        }).then((result) => {
            if (result.isConfirmed) {
                setLoading(true)
                postDelete(
                    '/admin/dtd/delete',
                    {
                        _method: 'DELETE',
                        nik: params.nik
                    },
                    decryptedUser,
                    () => {
                        setLoading(false)
                        setDeleting(!deleting)
                    },
                    (error) => {
                        setLoading(false)
                        console.log(error);
                    }
                )
            }
        })
    }

    return (
        <SoftBox my={3}>
            <Grid container spacing={3} mb={3} justifyContent='center'>
                <Grid item xs={12} lg={12}>
                    <MiniStatisticsCard
                        title={{ text: "Total Data" }}
                        count={tableData.rowCount}
                        // percentage={{ color: "success", text: "+55%" }}
                        icon={{ color: "info", component: "paid" }}
                    />
                </Grid>
            </Grid>

            <Grid container spacing={3} justifyContent='center'>
                <Grid item xs={12} lg={12}>
                    <Card>
                        <CardHeader
                            title={
                                <Typography>
                                    Tabel Pendataan
                                </Typography>
                            }
                        />
                        <CardContent>
                            <Grid container justifyContent="space-between" >
                                <Grid item xs={12}>
                                    <ServerSideTable
                                        loading={loading}
                                        data={tableData.data}
                                        columns={columns}
                                        rowCount={tableData.rowCount}
                                        baseUrl="/admin/dtd"
                                        pagination={pagination}
                                        setPagination={setPagination}
                                        columnFilter={columnFilter}
                                        setColumnFilter={setColumnFilter}
                                        columnVisibility={columnVisibility}
                                        deleteFunc = {deleteData}
                                    />
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </SoftBox>
    )
}

export default PendataanDTD