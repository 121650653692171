/**
=========================================================
* Argon Dashboard 2 MUI - v3.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-material-ui
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Argon Dashboard 2 MUI Base Styles
import borders from "assets/theme-dark/base/borders";

// Argon Dashboard 2 MUI Helper Functions
import pxToRem from "assets/theme-dark/functions/pxToRem";

const { borderRadius } = borders;

const cardMedia = {
    styleOverrides: {
        root: {
            borderRadius: borderRadius.xl,
            margin: `${pxToRem(16)} ${pxToRem(16)} 0`,
        },

        media: {
            width: "auto",
        },
    },
};

export default cardMedia;
