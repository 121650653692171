import { CardHeader, Skeleton } from '@mui/material'
import React from 'react'

const LoadingList = () => {
    return (
        <CardHeader
            avatar={<Skeleton animation="wave" variant="circular" width={40} height={40} />}
            title={
                <Skeleton
                    animation="wave"
                    height={20}
                    width="100%"
                    style={{ marginBottom: 6 }}
                />
            }
            subheader={<Skeleton animation="wave" height={10} width="60%" />}
        />
    )
}

export default LoadingList