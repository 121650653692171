import { ArrowBack, Save } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Card, CardContent, CardHeader, Grid, IconButton, MenuItem, Select, Tooltip } from '@mui/material';
import axios from 'axios';
import SoftBox from 'components/SoftBox';
import SoftInput from 'components/SoftInput';
import SoftTypography from 'components/SoftTypography';
import { CustomDescrypt } from 'global/func';
import { postSubmit } from 'global/postFunc';
import { serverUrl } from 'global/variable';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import userStore from 'store/userStore';

const Tambah = () => {
    const { user, addReq } = userStore(state => ({ user: state.user, addReq: state.addReq }));
    const decryptedUser = (user === null || user === undefined || user === '') ? "" : JSON.parse(CustomDescrypt(user));

    const navigate = useNavigate();

    const [loading, setLoading] = useState(false)

    const [formData, setFormData] = useState({})

    const [desas, setDesas] = useState([]);
    const [kecs, setKecs] = useState([]);
    const [desa, setDesa] = useState('');
    const [kec, setKec] = useState('');

    useEffect(() => {
        getKec()
    }, [])

    const getKec = async () => {
        const kec = await axios.get(serverUrl + '/kecamatan');

        setKecs(kec.data)
    }

    const handleChangeKec = (idKec) => {
        console.log(idKec);
        
        // setDesas([])
        if (idKec === '') {
            setKec('')
            setDesas('')
            setDesa('')
        }
        else {
            let resultObject = kecs.find(x => x.id_kec === idKec)
            setKec({ id_kec: idKec, nama_kec: resultObject.nama_kec })
            getDesa(idKec)
            // event.target.value !== '' && getDesa(event.target.value)
        }
    };

    const getDesa = async (params) => {
        const data = await axios.get(serverUrl + '/desa/' + params);
        setDesas(data.data)
    }

    const handleChangeDesa = (params) => {
        if (params === '') {
            setDesa('')
        }
        else {
            let resultObject = desas.find(x => x.id_desa === params)
            setDesa({ id_kel: params, nama_kel: resultObject.nama_desa })
        }
    };

    const submit = (event) => {
        event.preventDefault();
        let idRw = desa.id_kel + formData.rw.padStart(3, '0');
        let idRt = idRw + formData.rt.padStart(3, '0');
        let idTps = desa.id_kel + formData.nama_tps.padStart(3, '0');
        const frm = {
            nik: formData.nik,
            no_kk: formData.no_kk,
            nama: formData.nama,
            id_kec: kec.id_kec,
            nama_kec: kec.nama_kec,
            id_kel: desa.id_kel,
            nama_kel: desa.nama_kel,
            rw: formData.rw,
            id_rw: idRw,
            rt: formData.rt,
            id_rt: idRt,
            nama_tps: formData.nama_tps,
            id_tps: idTps
        }
        console.log(frm);
        
        setLoading(true)
        addReq()
        postSubmit(
            '/admin/dpt/insert',
            frm,
            decryptedUser,
            afterSubmit,
            (error) => {
                setLoading(false)
                console.log(error);
            }
        )
    }

    const afterSubmit = () => {
        navigate(-1)
        setLoading(false)
    }

    useEffect(() => {
        console.log(formData);

    }, [formData])

    return (
        <SoftBox mb={3}>
            <Grid container spacing={3} justifyContent='center' py={5}>
                <Grid item xs={12} sm={12} md={9}>
                    <Card >
                        <CardHeader
                            title={
                                <SoftTypography variant='h6'>Kembali</SoftTypography>
                            }
                            avatar={
                                <Tooltip title="Kembali">
                                    <IconButton variant="outlined" onClick={() => navigate(-1)}><ArrowBack /></IconButton>
                                </Tooltip>
                            }
                        />
                        <CardContent component='form' onSubmit={submit}>
                            <Grid container spacing={3} justifyContent="center">
                                <Grid item xs={12} sm={12} lg={6}>
                                    <SoftBox mb={2} justifyContent="center">
                                        <SoftBox mb={1} ml={0.5}>
                                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                NIK
                                            </SoftTypography>
                                        </SoftBox>
                                        <SoftInput
                                            type="text"
                                            name="nik"
                                            defaultValue={formData.nik || ''}
                                            onChange={(event) => setFormData({ ...formData, [event.target.name]: event.target.value })}
                                            autoComplete='off'
                                            required
                                        />
                                    </SoftBox>
                                </Grid>
                            </Grid>
                            <Grid container spacing={3} justifyContent="center">
                                <Grid item xs={12} sm={12} lg={6}>
                                    <SoftBox mb={2} justifyContent="center">
                                        <SoftBox mb={1} ml={0.5}>
                                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                No KK
                                            </SoftTypography>
                                        </SoftBox>
                                        <SoftInput
                                            type="text"
                                            name="no_kk"
                                            defaultValue={formData.no_kk || ''}
                                            onChange={(event) => setFormData({ ...formData, [event.target.name]: event.target.value })}
                                            autoComplete='off'
                                            required
                                        />
                                    </SoftBox>
                                </Grid>
                            </Grid>
                            <Grid container spacing={3} justifyContent="center">
                                <Grid item xs={12} sm={12} lg={6}>
                                    <SoftBox mb={2} justifyContent="center">
                                        <SoftBox mb={1} ml={0.5}>
                                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                Nama
                                            </SoftTypography>
                                        </SoftBox>
                                        <SoftInput
                                            type="text"
                                            name="nama"
                                            defaultValue={formData.nama || ''}
                                            onChange={(event) => setFormData({ ...formData, [event.target.name]: event.target.value })}
                                            autoComplete='off'
                                            required
                                        />
                                    </SoftBox>
                                </Grid>
                            </Grid>
                            <Grid container spacing={3} justifyContent="center">
                                <Grid item xs={12} sm={12} lg={6}>
                                    <SoftBox mb={1} ml={0.5}>
                                        <SoftTypography component="label" variant="caption" fontWeight="bold">
                                            Pilih Kecamatan
                                        </SoftTypography>
                                    </SoftBox>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        defaultValue=''
                                        value={kec.id_kec || ''}
                                        label="Kecamatan"
                                        onChange={(e) => handleChangeKec(e.target.value)}
                                        required
                                    >
                                        <MenuItem value=''><em>Pilih Kecamatan</em></MenuItem>
                                        {
                                            kecs.map((kec) => (
                                                <MenuItem key={kec.id_kec} value={kec.id_kec}>{kec.nama_kec}</MenuItem>
                                            ))
                                        }
                                    </Select>
                                </Grid>
                            </Grid>
                            <Grid container spacing={3} justifyContent="center">
                                <Grid item xs={12} sm={12} lg={6}>
                                    <SoftBox mb={1} ml={0.5}>
                                        <SoftTypography component="label" variant="caption" fontWeight="bold">
                                            Pilih Kelurahan
                                        </SoftTypography>
                                    </SoftBox>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        defaultValue=''
                                        value={desa.id_kel || ''}
                                        label="Desa"
                                        onChange={(e) => handleChangeDesa(e.target.value)}
                                        required
                                    >
                                        <MenuItem value=''><em>Pilih Desa</em></MenuItem>
                                        {
                                            desas.map((des) => (
                                                <MenuItem key={des.id_desa} value={des.id_desa}>{des.nama_desa}</MenuItem>
                                            ))
                                        }
                                    </Select>
                                </Grid>
                            </Grid>
                            <Grid container spacing={3} justifyContent="center">
                                <Grid item xs={12} sm={12} lg={6}>
                                    <SoftBox mb={2} justifyContent="center">
                                        <SoftBox mb={1} ml={0.5}>
                                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                RW
                                            </SoftTypography>
                                        </SoftBox>
                                        <SoftInput
                                            type="text"
                                            name="rw"
                                            defaultValue={formData.rw || ''}
                                            onChange={(event) => setFormData({ ...formData, [event.target.name]: event.target.value })}
                                            autoComplete='off'
                                            required
                                        />
                                    </SoftBox>
                                </Grid>
                            </Grid>
                            <Grid container spacing={3} justifyContent="center">
                                <Grid item xs={12} sm={12} lg={6}>
                                    <SoftBox mb={2} justifyContent="center">
                                        <SoftBox mb={1} ml={0.5}>
                                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                RT
                                            </SoftTypography>
                                        </SoftBox>
                                        <SoftInput
                                            type="text"
                                            name="rt"
                                            defaultValue={formData.rt || ''}
                                            onChange={(event) => setFormData({ ...formData, [event.target.name]: event.target.value })}
                                            autoComplete='off'
                                            required
                                        />
                                    </SoftBox>
                                </Grid>
                            </Grid>
                            <Grid container spacing={3} justifyContent="center">
                                <Grid item xs={12} sm={12} lg={6}>
                                    <SoftBox mb={2} justifyContent="center">
                                        <SoftBox mb={1} ml={0.5}>
                                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                TPS
                                            </SoftTypography>
                                        </SoftBox>
                                        <SoftInput
                                            type="text"
                                            name="nama_tps"
                                            defaultValue={formData.nama_tps || ''}
                                            onChange={(event) => setFormData({ ...formData, [event.target.name]: event.target.value })}
                                            autoComplete='off'
                                            required
                                        />
                                    </SoftBox>
                                </Grid>
                            </Grid>

                            <Grid container justifyContent='center' mt={2}>
                                <Grid item xs={12} sm={12} lg={6}>
                                    <Grid container justifyContent='flex-end'>
                                        <Grid item>
                                            <SoftBox mb={1} ml={0.5}>
                                                <LoadingButton
                                                    loading={loading}
                                                    loadingPosition="start"
                                                    startIcon={<Save />}
                                                    variant="contained"
                                                    type='submit'
                                                >
                                                    Save
                                                </LoadingButton>
                                            </SoftBox>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </CardContent>

                    </Card>
                </Grid>
            </Grid>
        </SoftBox>
    )
}

export default Tambah