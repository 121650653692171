function collapseItem(theme, ownerState) {
    const { palette, transitions, breakpoints, boxShadows, borders, functions } = theme;
    const { active, darkSidenav, sidenavColor, miniSidenav } = ownerState;

    const { dark, text, transparent, white } = palette;
    const { xxl } = boxShadows;
    const { borderRadius } = borders;
    const { pxToRem, rgba } = functions;

    return {
        background: active
            ? rgba(palette[sidenavColor ?? "info"].main, sidenavColor ? 1 : 0.1)
            : transparent.main,
        color: () => {
            let result = text.main;

            if ((active && sidenavColor) || (active && darkSidenav) || darkSidenav) {
                result = white.main;
            } else if (active) {
                result = dark.main;
            }

            return result;
        },
        display: miniSidenav ? "block" : "flex",
        alignItems: "center",
        width: "100%",
        padding: `${pxToRem(10.8)} ${pxToRem(16)} ${pxToRem(10.8)} ${pxToRem(16)}`,
        margin: `0 ${pxToRem(8)}`,
        borderRadius: borderRadius.md,
        cursor: "pointer",
        userSelect: "none",
        whiteSpace: "nowrap",
        boxShadow: active && darkSidenav ? xxl : "none",

        [breakpoints.up("xl")]: {
            boxShadow: () => {
                if (active) {
                    return darkSidenav ? xxl : "none";
                }

                return "none";
            },
            transition: transitions.create("box-shadow", {
                easing: transitions.easing.easeInOut,
                duration: transitions.duration.shorter,
            }),
        },
    };
}

function collapseIconBox(theme, ownerState) {
    const { transitions, borders, functions } = theme;
    const { darkSidenav, sidenavColor, active } = ownerState;

    const { borderRadius } = borders;
    const { pxToRem } = functions;

    return {
        color: "inherit",
        minWidth: pxToRem(32),
        minHeight: pxToRem(32),
        borderRadius: borderRadius.md,
        display: "grid",
        placeItems: "center",
        transition: transitions.create("margin", {
            easing: transitions.easing.easeInOut,
            duration: transitions.duration.standard,
        }),

        "& svg, svg g": {
            fill: "currentColor",
        },

        "& i": {
            color: active && (darkSidenav || sidenavColor) ? "inherit" : null,
        },
    };
}

const collapseIcon = ({ palette: { white, gradients } }, { active }) => ({
    color: active ? white.main : gradients.dark.state,
});

function collapseText(theme, ownerState) {
    const { typography, transitions, breakpoints, functions } = theme;
    const { miniSidenav, active } = ownerState;

    const { size, fontWeightMedium, fontWeightRegular } = typography;
    const { pxToRem } = functions;

    return {
        color: "inherit",
        marginLeft: pxToRem(4),

        [breakpoints.up("xl")]: {
            opacity: miniSidenav ? 0 : 1,
            maxWidth: miniSidenav ? 0 : "100%",
            marginLeft: miniSidenav ? 0 : pxToRem(4),
            transition: transitions.create(["opacity", "margin"], {
                easing: transitions.easing.easeInOut,
                duration: transitions.duration.standard,
            }),
        },

        "& span": {
            color: "inherit",
            fontWeight: active ? fontWeightMedium : fontWeightRegular,
            fontSize: size.sm,
            lineHeight: 0,
        },
    };
}

function collapseChildItem(theme, ownerState) {
    const { palette, borders, functions } = theme;
    const { active } = ownerState;

    const { dark, text } = palette;
    const { borderRadius } = borders;
    const { pxToRem } = functions;

    return {
        // background: active && transparentSidenav ? white.main : transparent.main,
        color: active ? dark.main : text.main,
        display: "flex",
        alignItems: "center",
        width: "100%",
        padding: `${pxToRem(10.8)} ${pxToRem(12.8)} ${pxToRem(10.8)} ${pxToRem(16)}`,
        margin: `0 ${pxToRem(16)}`,
        borderRadius: borderRadius.md,
        cursor: "pointer",
        userSelect: "none",
        whiteSpace: "nowrap",
    };
}

function collapseChildIconBox(theme, ownerState) {
    const { palette, transitions, functions } = theme;
    const { active } = ownerState;

    const { gradients, dark, text } = palette;
    const { pxToRem } = functions;

    return {
        minWidth: pxToRem(32),
        minHeight: pxToRem(32),
        // borderRadius: borderRadius.md,
        display: "grid",
        placeItems: "center",
        transition: transitions.create("margin", {
            easing: transitions.easing.easeInOut,
            duration: transitions.duration.standard,
        }),
        color: active ? dark.main : text.main,

        "& svg, svg g": {
            fill: active ? dark.main : gradients.dark.state,
        },
    };
}

function collapseChildIcon(theme, ownerState) {
    const { palette } = theme;
    const { active } = ownerState;

    const { dark, text } = palette;

    return {
        // color: active ? dark.main : text.main,
        width: active ? ".6rem" : ".4rem",
        minWidth: ".4rem",
        maxWidth: ".6rem"
    }
};

export { collapseItem, collapseIconBox, collapseIcon, collapseText, collapseChildItem, collapseChildIconBox, collapseChildIcon };
