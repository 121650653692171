/**
=========================================================
* Argon Dashboard 2 MUI - v3.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-material-ui
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Argon Dashboard 2 MUI base styles
import colors from "assets/theme-dark/base/colors";
import typography from "assets/theme-dark/base/typography";

const { text } = colors;
const { size } = typography;

const breadcrumbs = {
    styleOverrides: {
        li: {
            lineHeight: 0,
        },

        separator: {
            fontSize: size.sm,
            color: text,
        },
    },
};

export default breadcrumbs;
