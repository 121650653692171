import { AddCircleOutline } from '@mui/icons-material'
import { Button, Card, CardContent, CardHeader, Grid, Typography } from '@mui/material'
import MiniStatisticsCard from 'components/Cards/StatisticsCards/MiniStatisticsCard'
import SoftBox from 'components/SoftBox'
import { CustomDescrypt } from 'global/func'
import React, { useEffect, useState } from 'react'
import { Link, Route, Routes } from "react-router-dom"
import userStore from 'store/userStore'
import TambahPj from './TambahPj'
import Detail from './Detail'
import TambahST from './TambahST'
import { getDataWithResponse } from 'global/getFunc'
import MyTable from 'myComponent/Table/MyTable'
import { postDelete } from 'global/postFunc'
import Swal from 'sweetalert2'

const SuaraTambahan = () => {
    return (
        <Routes>
            <Route exact path='' element={<Data />} />
            <Route exact path='detail' element={<Detail />} />
            <Route exact path='tambah-pj' element={<TambahPj />} />
            <Route exact path='tambah-st' element={<TambahST />} />
            {/* <Route exact path='edit' element={<Edit />} /> */}
        </Routes>
    )
}

const Data = () => {
    const { user, addReq } = userStore(state => ({ user: state.user, addReq: state.addReq }));
    const decryptedUser = (user === null || user === undefined || user === '') ? "" : JSON.parse(CustomDescrypt(user));

    const [loading, setLoading] = useState(true)
    const [tableData, setTableData] = useState([]);
    const [deleting, setDeleting] = useState(false);

    useEffect(() => {
        requestData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deleting])

    const requestData = () => {
        setLoading(true)
        addReq()
        getDataWithResponse(
            '/entry/suara-tambahan/pj',
            decryptedUser,
            (res) => {
                setLoading(false)
                setTableData(res)
            },
            (res) => {
                setLoading(false)
                console.log(res);
            }
        );
    }

    // const deleteData = (params) => {
    //     Swal.fire({
    //         title: 'Hapus Penanggung Jawab?',
    //         icon: 'question',
    //         text: "Data Pemilih yang direkrut akan Otomatis Terhapus",
    //         showCancelButton: true,
    //         confirmButtonText: 'Hapus',
    //         cancelButtonText: 'Batal',
    //         confirmButtonColor: '#dd3333',
    //         // cancelButtonColor: '#d33',
    //     }).then((result) => {
    //         if (result.isConfirmed) {
    //             setLoading(true)
    //             postDelete(
    //                 '/entry/suara-tambahan/delete-pj',
    //                 {
    //                     _method: 'DELETE',
    //                     id: params
    //                 },
    //                 decryptedUser,
    //                 () => {
    //                     setLoading(false)
    //                     setDeleting(!deleting)
    //                 },
    //                 (error) => {
    //                     setLoading(false)
    //                     console.log(error);
    //                 }
    //             )
    //         }
    //     })
    // }

    const columns = [
        {
            accessorKey: 'id',
            header: 'ID'
        },
        {
            accessorKey: 'no_hp',
            header: 'No HP'
        },
        {
            accessorKey: 'nama',
            header: 'Nama'
        },
    ]

    const columnVisibility = {
        id: false,
    }

    const deleteData = (params) => {
        Swal.fire({
            title: 'Hapus Data?',
            icon: 'warning',
            text: "Hapus Data " + params.nama + '?',
            showCancelButton: true,
            confirmButtonText: 'Hapus',
            cancelButtonText: 'Batal',
            confirmButtonColor: '#dd3333',
            // cancelButtonColor: '#d33',
        }).then((result) => {
            if (result.isConfirmed) {
                setLoading(true)
                postDelete(
                    '/entry/suara-tambahan/delete-pj',
                    {
                        _method: 'DELETE',
                        id: params.id
                    },
                    decryptedUser,
                    () => {
                        setLoading(false)
                        setDeleting(!deleting)
                    },
                    (error) => {
                        setLoading(false)
                        console.log(error);
                    }
                )
            }
        })
    }

    return (
        <SoftBox my={3}>
            <Grid container spacing={3} mb={3} justifyContent='center'>
                <Grid item xs={12} lg={12}>
                    <MiniStatisticsCard
                        title={{ text: "Total Data" }}
                        count={tableData.length}
                        // percentage={{ color: "success", text: "+55%" }}
                        icon={{ color: "info", component: "paid" }}
                    />
                </Grid>
            </Grid>

            <Grid container spacing={3} justifyContent='center'>
                <Grid item xs={12} lg={12}>
                    <Card >
                        <CardHeader
                            title={
                                <Typography>
                                    Penanggung Jawab
                                </Typography>
                            }
                            action={
                                <Button
                                    variant="contained"
                                    endIcon={<AddCircleOutline color='white' />}
                                    // onClick={() => handleOpenDialog2()}
                                    to="/entry/suara-tambahan/tambah-pj"
                                    component={Link}
                                // disabled={tableData.length >= 10}
                                >
                                    Tambah
                                </Button>
                            }
                        />

                        <CardContent>
                            {/* <Grid container justifyContent="center" p={2}>
                                <Grid item lg={12}> */}
                                    <MyTable
                                        data={tableData ?? []}
                                        columns={columns}
                                        baseUrl="/entry/suara-tambahan"
                                        columnVisibility={columnVisibility}
                                        deleteFunc={deleteData}
                                        // exportFunc={exportData}
                                    />
                                {/* </Grid>
                            </Grid> */}
                        </CardContent>


                        {/* {
                            loading ?
                                (
                                    <>
                                        <LoadingList />
                                        <Divider />
                                        <LoadingList />
                                        <Divider />
                                        <LoadingList />
                                        <Divider />
                                        <LoadingList />
                                        <Divider />
                                        <LoadingList />
                                        <Divider />
                                        <LoadingList />
                                        <Divider />
                                        <LoadingList />
                                        <Divider />
                                        <LoadingList />
                                        <Divider />
                                        <LoadingList />
                                        <Divider />
                                        <LoadingList />
                                        <Divider />
                                    </>
                                )
                                :
                                tableData.length > 0 ?
                                    (
                                        <Grid container justifyContent="center" p={2}>
                                            <Grid item xl={12}>
                                                <List sx={{ bgcolor: 'background.paper' }}>
                                                    {
                                                        tableData.map((data) => (
                                                            <React.Fragment key={data.id}>
                                                                <ListItem
                                                                    alignItems="flex-start"
                                                                    secondaryAction={
                                                                        <IconButton key={1} edge="end" aria-label="delete" onClick={() => deleteData(data.id)}>
                                                                            <Delete color='error' />
                                                                        </IconButton>

                                                                    }
                                                                >
                                                                    <ListItemButton
                                                                        component={Link} to="/entry/suara-tambahan/detail" state={data}
                                                                    >
                                                                        <ListItemAvatar>
                                                                            <Avatar>
                                                                                <Interests />
                                                                            </Avatar>
                                                                        </ListItemAvatar>
                                                                        <ListItemText
                                                                            primary={
                                                                                <Typography
                                                                                    variant="body1"
                                                                                    color="text.primary"
                                                                                >
                                                                                    {data.nama}
                                                                                </Typography>
                                                                            }
                                                                            secondary={
                                                                                <React.Fragment>
                                                                                    <Typography
                                                                                        sx={{ display: 'inline' }}
                                                                                        component="span"
                                                                                        variant="body2"
                                                                                        color="text.primary"
                                                                                    >
                                                                                        {data.no_hp}
                                                                                    </Typography>
                                                                                </React.Fragment>

                                                                            }
                                                                        />
                                                                    </ListItemButton>
                                                                </ListItem>
                                                                <Divider />
                                                            </React.Fragment>
                                                        ))
                                                    }
                                                </List>
                                            </Grid>
                                        </Grid>
                                    )
                                    :
                                    <CardContent>
                                        <Typography variant="body2" color="text.secondary">
                                            Tidak ada data
                                        </Typography>
                                    </CardContent>

                        } */}
                    </Card>
                </Grid>
            </Grid>
        </SoftBox>
    )
}

export default SuaraTambahan