import { Card, CardContent, CardHeader, Grid, Typography } from '@mui/material'
import axios from 'axios'
import MiniStatisticsCard from 'components/Cards/StatisticsCards/MiniStatisticsCard'
import SoftBox from 'components/SoftBox'
import { CustomDescrypt } from 'global/func'
import { serverUrl } from 'global/variable'
import MapComponent from 'myComponent/MapComponent'
import SelectDesa from 'myComponent/SelectDesa'
import SelectKec from 'myComponent/SelectKec'
import MyTable from 'myComponent/Table/MyTable'
import React, { useEffect, useState } from 'react'
import { Route, Routes } from 'react-router-dom'
import userStore from 'store/userStore'
import Swal from 'sweetalert2'
import Detail from './Detail'

const Apk = () => {
    return (
        <Routes>
            <Route exact path='' element={<Data />} />
            <Route exact path='detail' element={<Detail />} />
            {/* <Route exact path='tambah' element={<Tambah />} /> */}
            {/* <Route exact path='edit' element={<Edit />} /> */}
        </Routes>
    )
}

const Data = () => {
    const encryptUser = localStorage.getItem('AuthPersist');
    const encryptUserJson = JSON.parse(encryptUser);
    const decryptedUser = encryptUserJson.state.user === null ? "" : JSON.parse(CustomDescrypt(encryptUserJson.state.user));
    const logOut = userStore((state) => state.logOut)

    const [loading, setLoading] = useState(true)
    const [tableData, setTableData] = useState([]);
    const [deleting, setDeleting] = useState(false);

    const [formData, setFormData] = useState({
        // semua: true,
        // tanggal: tanggal,
        // id_kab: idKab,
        id_kec: '',
        id_kel: ''
    })

    useEffect(() => {
        requestData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deleting, formData])

    const requestData = () => {
        // const decodedUser = decodeToken(decryptedUser);
        setLoading(true)
        axios.post(
            serverUrl + '/admin/apk',
            formData,
            {
                headers: {
                    'Authorization': `Bearer ${decryptedUser}`,
                    'content-type': 'multipart/form-data'
                }
            })
            .then((response) => {
                // console.log(response);
                setTableData(response.data)
                setLoading(false)
            })
            .catch((error) => {
                console.log(error);
                if (error.response.status === 401 && error.response.data.messages === "Access Token Expired") {
                    logOut();
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: error.response.status + ' ' + error.response.statusText
                    })
                }
                setLoading(false)
            })
    }

    // const deleteData = (params) => {
    //     console.log(params);
    //     Swal.fire({
    //         title: 'Hapus data?',
    //         text: `Data ${params} akan dihapus`,
    //         icon: 'warning',
    //         showCancelButton: true,
    //         confirmButtonColor: '#3085d6',
    //         cancelButtonColor: '#d33',
    //         confirmButtonText: 'Ya, hapus!',
    //         showLoaderOnConfirm: true
    //     }).then((result) => {
    //         if (result.isConfirmed) {
    //             setLoading(true)
    //             axios.post(
    //                 serverUrl + '/korkel/apk/delete',
    //                 {
    //                     _method: 'DELETE',
    //                     id: params,
    //                 },
    //                 {
    //                     headers: {
    //                         'Authorization': `Bearer ${decryptedUser}`,
    //                         'content-type': 'multipart/form-data'
    //                     }
    //                 })
    //                 .then((response) => {
    //                     setLoading(false)
    //                     // console.log(response);
    //                     Swal.fire('Berhasil!', response.data, 'success');
    //                     setDeleting(!deleting)
    //                 })
    //                 .catch((error) => {
    //                     setLoading(false)
    //                     console.log(error);
    //                     if (error.response.status === 401 && error.response.data.messages === "Access Token Expired") {
    //                         logOut();
    //                     }
    //                     else {
    //                         Swal.fire({
    //                             icon: 'error',
    //                             title: 'Oops...',
    //                             text: error.response.status + ' ' + error.response.statusText
    //                         })
    //                     }
    //                 })
    //         }
    //     })
    // }

    const columns = [
        //fields = { ['no_kk', 'nik_kepala', 'nama_kepala', 'pekerjaan', 'jml_anggota', 'no_hp', 'created_at', 'nama_petugas']}
        //headers = { ['No KK', 'NIK KK', 'Nama KK', 'Pekerjaan', 'Jml Anggota', 'No. HP', 'Jam Input', 'Tim']}
        {
            accessorKey: 'nama',
            header: 'APK',
            filterVariant: 'text',
        },
        {
            accessorKey: 'id_kec',
            header: 'ID_KEC'
        },
        {
            accessorKey: 'nama_kec',
            header: 'Kec',
            // filterVariant: 'select',
            // filterSelectOptions: listKec,
        },
        {
            accessorKey: 'id_kel',
            header: 'ID_KEL',
        },
        {
            accessorKey: 'nama_kel',
            header: 'Kel',
            // filterVariant: 'select',
            // filterSelectOptions: listKel,
        },
    ]

    const columnVisibility = {
        id_kec: false,
        // nama_kec: false,
        id_kel: false,
        // nama_kel: false,
        // dtd_nama: false,
        // tgl_lahir: false
    }

    return (
        <SoftBox my={3}>
            <Grid container spacing={3} mb={2} justifyContent='center'>
                <Grid item xs={12} lg={12}>
                    <MiniStatisticsCard
                        title={{ text: "Total Data" }}
                        count={tableData.length}
                        // percentage={{ color: "success", text: "+55%" }}
                        icon={{ color: "info", component: "paid" }}
                    />
                </Grid>
            </Grid>

            <Grid container spacing={2} mb={2} justifyContent='center'>
                <Grid item xs={12} lg={12}>
                    <Card >
                        <CardContent sx={{ mt: 2 }}>
                            <MapComponent data={tableData} />
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>

            <Grid container spacing={3} justifyContent='center'>
                <Grid item xs={12} lg={12}>
                    <Card>
                        <CardHeader
                            title={
                                <Typography>
                                    Data APK
                                </Typography>
                            }
                        />
                        <CardContent>
                            <Grid container spacing={2}>
                                <Grid item xs={12} lg={4}>
                                    <SelectKec formData={formData} setFormData={setFormData} />
                                </Grid>
                                <Grid item xs={12} lg={4}>
                                    <SelectDesa formData={formData} setFormData={setFormData} idKec={formData.id_kec} />
                                </Grid>
                            </Grid>
                            <Grid container justifyContent="space-between" >
                                <Grid item xs={12}>
                                    <MyTable
                                        data={tableData ?? []}
                                        // headers={['No KK', 'NIK KK', 'Nama KK', 'Pekerjaan', 'Jml Anggota', 'No. HP', 'Jam Input', 'Tim']}
                                        // fields={['no_kk', 'nik_kepala', 'nama_kepala', 'pekerjaan', 'jml_anggota', 'no_hp', 'created_at', 'nama_petugas']}
                                        columns={columns}
                                        deleting={deleting}
                                        setDeleting={setDeleting}
                                        columnVisibility={columnVisibility}
                                        // deleteUrl={serverUrl + '/admin/apk/delete'}
                                        deleteField='id_apk'
                                        detailUrl={'/detail'}
                                        state={{ isLoading: loading }}
                                    />
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </SoftBox>
    )
}

export default Apk