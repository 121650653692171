import { create } from 'zustand'
import { createJSONStorage, persist } from 'zustand/middleware'

const userStore = create(
    persist(
        (set, get) => ({
            user: null,
            setUser: (data) => set({ user: data }),
            logOut: () => set({
                user: null,
                tahunAnggaran: null
            }),
            reqCount: 0,
            addReq: () => set({ reqCount: get().reqCount + 1 }),
        }),
        {
            name: 'AuthPersist',
            getStorage: () => createJSONStorage(() => localStorage),
        }
    )
)

export default userStore