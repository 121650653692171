import { Save } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { Card, CardContent, CardHeader, Grid, Typography } from '@mui/material'
import MiniStatisticsCard from 'components/Cards/StatisticsCards/MiniStatisticsCard'
import SoftBox from 'components/SoftBox'
import SoftInput from 'components/SoftInput'
import SoftTypography from 'components/SoftTypography'
import { CustomDescrypt } from 'global/func'
import { getDataWithResponse } from 'global/getFunc'
import { postSubmit } from 'global/postFunc'
import MapComponent from 'myComponent/MapComponent'
import React, { useEffect, useState } from 'react'
import { Route, Routes } from 'react-router-dom'
import userStore from 'store/userStore'

const Lokasi = () => {
    return (
        <Routes>
            <Route exact path='' element={<Data />} />
            {/* <Route exact path='detail' element={<Detail />} /> */}
        </Routes>
    )
}

const Data = () => {
    const { user, addReq } = userStore(state => ({ user: state.user, addReq: state.addReq }));
    const decryptedUser = (user === null || user === undefined || user === '') ? "" : JSON.parse(CustomDescrypt(user));

    const [formData, setFormData] = useState({
        lat: '',
        longi: ''
    });

    const [updating, setUpdating] = useState(false)
    
    const [locationData, setLocationData] = useState({});

    const [loading, setLoading] = useState(true)

    useEffect(() => {
        requestData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updating])
    
    useEffect(() => {
        console.log(formData);
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formData])

    const requestData = () => {
        setLoading(true)
        addReq()
        getDataWithResponse(
            '/data-center',
            decryptedUser,
            (res) => {
                setLoading(false)
                setFormData(res)
                setLocationData(res)
            },
            (res) => {
                setLoading(false)
                console.log(res);
            }
        );
    }

    const submit = (event) => {
        event.preventDefault();
        setLoading(true)
        addReq()
        postSubmit(
            '/data-center/update',
            formData,
            decryptedUser,
            () => {
                setLoading(false)
                setUpdating(!updating)
            },
            (error) => {
                setLoading(false)
                console.log(error);
            }
        )
    }

    return (
        <SoftBox my={3}>
            <Grid container spacing={3} mb={3} justifyContent='center'>
                <Grid item xs={12} lg={12}>
                    <MiniStatisticsCard
                        title={{ text: "Total Data" }}
                        count='fsd'
                        // percentage={{ color: "success", text: "+55%" }}
                        icon={{ color: "info", component: "paid" }}
                    />
                </Grid>
            </Grid>

            <Grid container spacing={2} mb={2} justifyContent='center'>
                <Grid item xs={12} lg={12}>
                    <Card>
                        <CardContent sx={{ mt: 2 }}>
                            <MapComponent data={locationData} center={{ lat: parseFloat(locationData.lat), lng: parseFloat(locationData.longi) }} />
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>

            <Grid container spacing={3} justifyContent='center'>
                <Grid item xs={12} lg={12}>
                    <Card>
                        <CardHeader
                            title={
                                <Typography>
                                    Data Lokasi
                                </Typography>
                            }
                        />
                        <CardContent component='form' onSubmit={submit}>
                            <Grid container spacing={3} justifyContent="center">
                                <Grid item xs={12} sm={12} lg={6}>
                                    <SoftBox mb={2} justifyContent="center">
                                        <SoftBox mb={1} ml={0.5}>
                                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                Nama
                                            </SoftTypography>
                                        </SoftBox>
                                        <SoftInput
                                            type="text"
                                            value={formData.lat || ''}
                                            onChange={(event) => setFormData({ ...formData, lat: event.target.value })}
                                            autoComplete='off'
                                            required
                                        />
                                    </SoftBox>
                                </Grid>
                            </Grid>
                            <Grid container spacing={3} justifyContent="center" mb={1}>
                                <Grid item xs={12} sm={12} lg={6}>
                                    <SoftBox mb={2} justifyContent="center">
                                        <SoftBox mb={1} ml={0.5}>
                                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                No. HP
                                            </SoftTypography>
                                        </SoftBox>
                                        <SoftInput
                                            type="text"
                                            value={formData.longi || ''}
                                            onChange={(event) => setFormData({ ...formData, longi: event.target.value })}
                                            autoComplete='off'
                                            required
                                        />
                                    </SoftBox>
                                </Grid>
                            </Grid>
                            <Grid container justifyContent='center'>
                                <Grid item xs={12} sm={12} lg={6}>
                                    <Grid container justifyContent='flex-end'>
                                        <Grid item>
                                            <SoftBox mb={1} ml={0.5}>
                                                <LoadingButton
                                                    loading={loading}
                                                    loadingPosition="start"
                                                    startIcon={<Save />}
                                                    variant="contained"
                                                    type='submit'
                                                >
                                                    Save
                                                </LoadingButton>
                                            </SoftBox>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </SoftBox>
    )
}

export default Lokasi